@import 'styles/colors.scss';

.tools-box {
  padding: 40px 0 40px 0;
  border-bottom: 1px solid #ececec;

  &__section {
    display: flex;
    align-items: center;
  }
  
  &__header {
    margin: 0 0 0 10px;
  }

  &__header-text {
    color: $coal;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
  }

  &__outer {
    position: relative;
    height: 72px;
    width: 72px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__section {
    height: 30px;
  }

  &__link {
    text-decoration: none;
  }
  
  &__abbreviation {
    color: $dark-gray;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.67;
    opacity: 0.7;
  }
}