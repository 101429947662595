.horizontal-divider-list {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;

  &__divider {
    border-right: 1px solid #EBEBEB;
    margin: 0 0.5rem;
    height: 1rem;
    
    &:last-of-type {
      display: none;
    }
  }
}