@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.article-list-link {
  position: relative;
  overflow: hidden;
  min-width: 100%;
  height: 96px;
  margin: 0 10px 10px 10px;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
  text-align: left;

  &__image-container {
    position: relative;
    width: 120px;
    height: 96px;
    flex-shrink: 0;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text-container {
    width: 100%;
    height: 100%;
    padding: 10px 25px;
    background: white;
    color: $text-color;
    transition: all 0.15s ease-in-out;
  }

  &__heading {
    margin: 0;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  &__preamble {
    margin: 10px 0 0 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;

    &--regular-line-clamp {
      display: none;
    }
  }

  a {
    display: flex;
    height: 100%;
    flex-grow: 1;
    text-decoration: none;

    &:hover {
      &, * {
        text-decoration: underline;
      }

      .article-list-link__image-container::after {
        opacity: 0.3;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    height: 120px;

    &__image-container {
      width: 150px;
      height: 120px;
    }

    &__preamble {
      &--reduced-line-clamp {
        display: none;
      }

      &--regular-line-clamp {
        // Fallback for IE11.
        display: block;
        display: initial;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    min-width: 560px;
    max-width: 560px;
    height: 160px;
    margin: 0 0 20px 0;

    @media screen and (min-width: 1230px) {
      &:nth-of-type(odd) {
        margin-right: 20px;
      }

      &:last-of-type() {
        margin-right: 0;
      }
    }

    &__image-container {
      width: 200px;
      height: 160px;
    }
  }

  @include media-breakpoint-up(lg) {
    min-width: 560px;
    max-width: 560px;
    margin: 0 0 30px 0;

    @media screen and (min-width: 1231px) {
      &:nth-of-type(odd) {
        margin-right: 30px;
      }

      &:last-of-type() {
        margin-right: 0;
      }
    }

    &__text-container {
      padding: 17px 15px 24px 15px;
    }

    &__image-container {
      height: 160px;
    }

    &__title {
      color: $semi-black;
      font-size: 22px;
      line-height: 35px;
    }

    &__preamble {
      color: $black85;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
