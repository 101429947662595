@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.content-labels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  &__label {
    margin-top: 5px;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  &__time {
    align-self: flex-end;
    margin: 8px 0;
    color: $blue;
    font-size: 0.75rem;
  }

  &__channel {
    margin: 8px 0;
    color: $text-color;
    font-size: 0.75rem;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__filetype {
    display: flex;
    min-width: 65px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: $brownish-grey;
    color: $brownish-grey;
    font-size: 0.688rem;
  }
}