@import 'styles/breakpoints.scss';

.blog-post{
  &__post-container--portrait {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .blog-post__post {
      width: 50.5%;
    }

    .image {
      width: 39%;
      height: auto;
    }
  }

  &__post .image {
    width: auto;
  }

  &__labels-container--portrait {
    width: 50.5%;
  }

  &__hero-image-wrapper--portrait {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    .image {
      position: absolute;
      bottom: -5%;
      left: 0;
      width: 100%;
    }

    &::after {
      display: block;
      padding-top: 100%;
      content: '';
    }
  }
  
  &__same-topic-container {
    padding: 0 15px;
  }
}
