@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.related-content-editor {
  small {
    display: block;
    margin-top: 10px;
  }

  &__add {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 30px;
    margin-top: 30px;
    cursor: pointer;

    .icon--plus {
      width: 24px;
      height: 24px;
      margin-right: 7px;
      opacity: 0.7;

      .plus {
        fill: none;
        stroke: $black;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 1.5px;
      }

      .mask {
        display: none;
      }
    }

    p {
      margin: 0;
      font-size: 0.875rem;
    }

    &:hover,
    &:focus {
      .icon--plus {
        opacity: 1;

        .mask {
          display: block;
        }

        .plus {
          display: none;
        }
      }
    }
  }
}
