@import '../SideButtons.scss';

.embed-video-button__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.embed-video-button__label {
  display: inline-block;
  max-width: 32px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.embed-video-button__icon {
  width: auto;
  height: 32px;
  margin: 8px #{$horizontal-sidebar-button-padding} 0 #{$horizontal-sidebar-button-padding};
}

.md-sb-button svg.embed-video-button__icon path {
  fill: #fff;
}
