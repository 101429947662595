@import 'styles/breakpoints.scss';

.feed-tab {
  display: flex;
  justify-content: flex-start;
  width: auto;
  padding: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  margin: 30px 0 17px 0;
  list-style-type: none;

  &__link {
    margin-right: 15px;
    color: white;
    cursor: pointer;
    font-size: 1.125rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-right: 75px;
    }

    &--active {
      padding-bottom: 11px;
      box-shadow: 0 2px 0 white;
      font-weight: bolder;
    }
  }

  &--grey {
    border-bottom: solid 1px #e6e6e6;
    margin: 50px 0 47px 0;

    .feed-tab__item {
      margin: 0 auto;
    }

    .feed-tab__link {
      padding: 0 15px;
      color: #3f3f3f;
      cursor: pointer;
      font-size: 1.125rem;
      text-align: center;

      &--active {
        padding-bottom: 11px;
        box-shadow: 0 2px 0 #3f3f3f;
        font-weight: bolder;
      }
    }
  }
}