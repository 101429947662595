.child-page-menu {
  &__actions {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__add-page,
  &__edit-page,
  &__add-block {
    color: #fff;
    margin-right: 10px;
  }

  &__add-block {
    margin-right: 0;
  }

  &__mosaic-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0 -16px;
    list-style-type: none;
  }
}
