@import 'styles/breakpoints.scss';

.faq__group {
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 65px;
  }

  &-container {
    border-top: 1px solid #ccc;
  }

  h3 {
    margin-bottom: 15px;
  }

  &-toggle-button {
    position: absolute;
    right: 0;
    padding-right: 0;
    margin-top: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: lighter;

    span {
      font-size: 16px;
    }
  }
}

.faq__toggle-container {
  position: relative;
}
