@import 'styles/breakpoints.scss';

.comment-input {
  .title {
    padding: 0;
    margin-bottom: 1.5rem;

    h2 {
      font-family: 'Noto Sans', sans-serif;
      font-weight: 600;
    }
  }

  &__input-wrapper {
    display: flex;
  }

  &__input {
    flex: 1;
    padding: 0.5rem 0.75rem;
    margin: 0 0 1rem 1rem;;
    margin-left: 15px;
    font-size: 1rem;
    background: #FAFAFA;
    border: 1px solid #EBEBEB;
    color: #1A1A1A;

    &:focus-within {
      border: 1px solid #00A0D0;
      box-shadow: 0px 0px 0px 2px #C8E2EA;
    }
  }

  &__main {
    .public-DraftEditor-content {
      min-height: 5rem;
    }

    .comment-input__button {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      padding: 0.5rem 1.5rem;
      margin-left: auto;
      border: none;
    }
  }

  &__reply {
    margin-top: 1rem;

    .comment-input__button {
      display: block;
      margin-left: auto;
      border: none;
      padding: 0.563rem 1.125rem;
      line-height: 0.875rem;
      font-size: 0.813rem;
      font-weight: 600;
    }
  }
}
