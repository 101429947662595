@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.author-add {
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    height: 200px;
  }

  &--error {
    border: 1px solid $guardsman-red;
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon--plus {
    width: 24px;
    height: 24px;
    margin-right: 5px;

    .plus {
      fill: none;
      stroke: $black;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 1.5px;
    }

    .mask {
      display: none;
    }
  }
}
