@import 'styles/colors.scss';

.related-article{
  &--item {
    text-decoration: none;
    min-height: 100px;
    padding-bottom: 16px;

    > a {
      text-decoration: none;
    }

    &--container {
      display: flex;
    }

    &--image {
      height: 100px;
      width: 100px;
      object-fit: cover;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      justify-content: center;
    }

    &--date {
      color: #00A0D0;
      text-decoration: none;
      padding-bottom: 12px;
    }

    &--title {
      font-weight: 400;
      color: $coal;
    }
  }
}
