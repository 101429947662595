@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.insights-listing-body-box {
  padding: 48px 24px;

  @include media-breakpoint-up(md) {
    padding: 30px 24px 60px;
  }

  &--relative {
    position: relative;
  }

  &--blue {
    background: #f3f9fa;
  }

  &--white {
    background: $white;
  }

  .insights-listing-body-box {

    &__content {
      position: relative;
      margin: -126px auto 0;

      @include media-breakpoint-down(sm) {
        margin-top: -140px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 950px;
        padding-right: 280px;
      }

      h2 {
        color: $text-color;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        margin-top: 40px;

        &:nth-child(1) {
          margin-top: 0;
        }

        @include media-breakpoint-up(md) {
          font-size: 28px;
        }
      }

      p {
        color: $mine-shaft;
        font-size: 12px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      .insights-listing-body-box__preamble {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25;

        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
          line-height: 1.5;
        }

        @include media-breakpoint-down(sm) {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.75rem;
        }
      }
    }

    &__link {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__documents-wrapper {
      display: none;

      &.loaded {
        display: block;
      }

      .title {
        margin-top: 0;
      }

      .documents {
        padding-bottom: 15px;
      }
    }

    &__top-part-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &__top-part-like-and-share-wrapper {
      display: flex;
      margin-left: 1rem;

      .share-link__button {
        margin-right: 1rem;
        
        svg > path {
          fill: $neutral-200;
        }
        span {
          color: $neutral-200;
        }
      }

      .share-link__button:hover {
        opacity: 1;

        svg > path {
          fill: $neutral-0;
        }
        span {
          color: $neutral-0;
        }
      }

      .like-button {
        svg > path {
          stroke: $neutral-200;
        }
        span {
          color: $neutral-200;
          font-weight: 400;
        }
      }

      .like-button:hover {
        svg > path {
          fill: $neutral-0;
          stroke: $neutral-0;
        }
        span {
          color: $neutral-0;
        }
      }

      .comment-like-button--liked-by-me {
        svg > path {
          fill: $comments-and-likes-active;
          stroke: $comments-and-likes-active;
        }
      }

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }

    &__article-user-wrapper {
      display: flex;
      align-items: center;
    }

    &__article-user-text {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      color: $white;
      margin-left: 10px;
    }

    &__article-user-name {
      font-weight: bold;
    }

    &__article-user-link {
      color: $white;
      text-decoration: none;
    }

    &__article-date {
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }

    &__image {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 30px;

      @include media-breakpoint-down(sm) {
        max-width: none;
        width: calc(100% + 48px);
        margin: 0 -24px 30px;
      }
    }

    &__source-link-box {
      font-size: 12px;
      line-height: 16px;
      color: #666;
      padding: 0 0 30px;

      a {
        text-decoration: none;
      }
    }

    &__keywords-box {
      padding: 0 0 30px;

      hr {
        margin: 0;
      }
    }

    &__keywords-title {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: 'Noto Sans', sans-serif;
      color: #666;
      padding: 30px 0 0;
    }

    &__keywords-list {
      color: #666;
      padding: 15px 0 0;
    }

    &__keywords-link {
      font-size: 12px;
      color: #666;
      text-decoration: none;

      &:after {
        content: ', ';
      }

      &:last-child:after {
        content: '';
      }
    }

    &__main-text {
      padding: 0 0 30px;
    }

    &__main-text p {
      @include media-breakpoint-down(sm) {
        font-size: 13px;
        line-height: 1.5rem !important;
      }
    }

    &__comments {
      position: relative;
      padding-bottom: 3rem;
      background-color: #f3f9fa;
      margin-top: 20px;
      margin-bottom: -48px !important;

      @include media-breakpoint-up(md) {
        margin-bottom: -60px !important;
      }

      h2 {
        font-size: 20px;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1500px;
        background-color: #f3f9fa;
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }

      hr {
        opacity: 0;
      }
    }
  }
}
