@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.language-banner {
  background-color: $white;
  margin-bottom: 20px;

  &__wrapper {
    position: relative;
    width: 70%;

    @include media-breakpoint-up(md) {
      width: 97%;
    }
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &__description {
    width: 90%;
    color:#1a1a1a;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.125rem;
    line-height: 1.67;

    @include media-breakpoint-up(md) {
      width: 90%;
    }

    @include media-breakpoint-down(sm) {
      font-size: .8rem;
    }
  }

  &__link {
    margin-left: 4px;
    color: #18a5d2;
    cursor: pointer;
    text-decoration: underline;
  }
}
