@import 'styles/breakpoints.scss';

.comment-like-summary {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  background-color: white;

  a {
    text-decoration: none;
  }
}
