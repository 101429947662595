@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.sh-house {
  color: $white;
  display: flex;
  justify-content: center;
  height: 280px;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: 115px 0 0 20px;
    height: 860px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__roof-wrapper {
    text-align:center;
  }

  &__house-box-roof-filled {
    display: flex;
    align-items: flex-end;
    
    svg {
      #Roof_filled{
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__house-box-roof-border{
    display: flex;
    align-items: flex-end;
  }

  &__house-box-vision {
    position: relative;
    top: 16px;
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    font-size: 8px !important;;
    font-weight: 600 !important;
    line-height: 1.25 !important;
    letter-spacing: -0.12px;
    margin: 0;
    height: 0;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      top: 22px;
      font-size: 12px !important;
    }
  }

  &__title {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      height: auto;
      position: relative;
      left: 12px;
    }
    
    width: 360px;
    font-family: 'ASSA Vesta Regular', 'Open Sans', sans-serif !important;
    font-size: 50px !important;
    line-height: 1.07 !important;
  }

  &__roof-container {
    position: absolute;
    left: -20px;
    height: 50px;
    border-bottom: solid 2px $white;
    margin-bottom: 10px;
    width: 380px;
  }

  &__house-box-full-width {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 229px;
    height: 30px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 2px $white;
    margin-top: 10px;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 330px;
      height: 43px;
    }

    &:hover {
      cursor: pointer;
      color: #1a1a1a;
      background-color: $white;
    }
  }

  &__house-box-text {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 8px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
    letter-spacing: -0.12px;
    @include media-breakpoint-up(md) {
      font-size: 12px !important;
    }
  }

  &__house-box-part-width-container {
    display: flex;
    justify-content: space-between;
    width: 229px; 
    
    @include media-breakpoint-up(md) {
      width: 330px;
    }
  }

  &__house-box-part-width {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73px;
    height: 30px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 2px $white;
    text-align: center;
    margin: 10px auto 0;

    @include media-breakpoint-up(md) {
      margin: 10px auto 0;
      width: 106px;
      height: 43px;
      padding: 0;
    }

    &:hover {
      cursor: pointer;
      color: #1a1a1a;
      background-color: $white;
    }
  }

  &__house-box-part-width-text {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 8px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
    letter-spacing: -0.12px;
    @include media-breakpoint-up(md) {
      font-size: 12px !important;
    }
  }

  &__active {
    color: #1a1a1a;
    background-color: $white;
  }
  &__vision-border-active {
    color: $white;
  }
  &__vision-filled-active {
    color: #1a1a1a;
    position: relative;
    z-index: 10;
  }
}