@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.container {
  width: 100%;

  &--padding {
    padding: 0 15px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  &--teal {
    background-color: #f3f9fa;
  }

  &--gray {
    border-top: 1px solid $gallery;
    background-color: #f4f4f4;
  }

  &--darkgray {
    border-top: 1px solid $gallery;
    background-color: #404040;
  }

  &__outer {
    max-width: 1110px;
    margin: 0 auto;
  }

  &--thin {
    .container__outer {
      max-width: 724px;
    }
  }

  &--xxl {
    .container__outer {
      max-width: 1290px;
    }
  }

  &--xxxl {
    .container__outer {
      max-width: 1440px;
    }
  }

  &--xxxxl {
    .container__outer {
      max-width: 1700px;
    }
  }

  &--full {
    .container__outer {
      max-width: none;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-right: -15px;
    margin-left: -15px;

    &--vertical-align {
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &--mobile-row {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &--mobile-column-spacing {
    .container__inner {
      > .column {
        + .column {
          margin-top: 30px;

          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.space-between .container__inner {
    justify-content: space-between;
  }

  &.space-around .container__inner {
    justify-content: space-around;
  }

  @include media-breakpoint-up(md) {
    &--columns-1 {
      .column {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &--columns-2 {
      .column {
        min-width: 50%;
        max-width: 50%;
      }
    }

    &--columns-3 {
      .column {
        min-width: 33.3333333%;
        max-width: 33.3333333%;
      }
    }

    &--columns-4 {
      .column {
        min-width: 25%;
        max-width: 25%;
      }
    }

    &--columns-5 {
      .column {
        min-width: 20%;
        max-width: 20%;
      }
    }
  }
}
