@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.innovation-portal-results {
  position: relative;
  margin: -150px auto 25px;
  max-width: 997px;
  padding: 0 20px;

  &__list {
    background-color: white;
    box-shadow: 0 2px 8px rgba(9,88,112,.08);
    padding: 30px 50px;
  }

  &__results-length {
    display: block;
    color: white;
    font-style: italic;
  }

  &__sorting {
    display: flex;
    justify-content: space-between;
    padding: 0 70px;
  }

  &__item {
    list-style: none;
  }

  &__item-link {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: 30px 20px;
    margin-top: 20px;
    border-bottom: 1px solid #ececec;
  }

  &__item-content {
    padding-right: 50px;
    max-width: 500px;
  }

  &__item-time {
    display: block;
    color: #00a0d0;
    margin-bottom: 10px;
    font-size: 0.7rem;
  }

  &__item-title {
    font-family: 'Noto Sans', sans-serif !important;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__item-summary {
    font-family: 'Noto Sans', sans-serif;
    color: #4c4c4c;
  }
  
  &__item-image {
    cursor: pointer;
    max-width: 300px;
  }
  
  &__no-search-results {
    display: block;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
  }

  &__load-more-button {
    display: flex;
    justify-content: center;
    padding-bottom: 70px;
  }

  &__content-loader {
    position: relative;
    margin-top: -150px;
  }

  &__content-loader-title {
    display: block;
    color: white;
    font-style: italic;
    padding: 0 70px 21px;
  }

  &__content-loader-content {
    padding-top: 16px;
  }

  .load-more-button-black {
    display: block;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    &__results-length {
      display: none;
    }

    &__sorting {
      background-color: white;
      padding: 0;
      justify-content: flex-end;
    }

    .sorting-control {
      display: flex;
      padding: 9px 15px 15px;
      margin-bottom: 0;
    }

    &__list {
      padding: 10px;
    }

    &__item-link {
      padding: 10px;
      flex-direction: column;
    }

    &__item-content {
      padding: 0;
    }

    &__item-title {
      font-size: 1.125rem !important;
      margin-bottom: 10px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      
    }

    &__item-summary {
      font-size: 0.875rem;
    }
  }
}