@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.folder-entry {
  &__container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    padding: 2px 0 2px 7px;
    color: $mine-shaft;
    height: 100%;

    &:hover {
      padding-left: 4px;
      border-left: 3px solid $blue90;
      background-color: $blue20;
    }

    &--upload-mode:hover {
      padding-left: 7px;
      border-left-width: 0;
      background-color: transparent;
    }
  }

  &__icon {
    width: auto;
    max-height: 30px;
  }

  &__column {
    display: flex;
    min-height: 35px;
    cursor: pointer;

    &:first-child {
      min-height: 35px;
      width: 30px;
      margin: auto 15px auto 0;
    }

    &:nth-child(2) {
      width: 100%;
    }
    
    &:nth-child(3) {
      min-width: 70px;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &:nth-child(4) {
      min-width: 150px;
      @include media-breakpoint-down(xs) {
        min-width: 0px;
      }
    }
  }

  &__empty {
    margin-right: 30px;
  }

  &__icon,
  &__filename,
  &__size,
  &__date {
    display: inline-block;
    margin: auto 0;
    font-size: 14px;
  }

  // Override necessary since insights-listing-body-box__content *:nth-last-child(1) uses !important
  // with high specificity.
  &__icon.folder-entry__icon.folder-entry__icon.folder-entry__icon,
  &__filename.folder-entry__filename.folder-entry__filename.folder-entry__filename,
  &__size.folder-entry__size.folder-entry__size.folder-entry__size,
  &__date.folder-entry__date.folder-entry__date.folder-entry__date {
    margin-bottom: auto !important;
    font-size: 14px;
  }

  &__size {
    margin-right: 15px;
  }

  &__filename:hover {
    color: $blue90;
    text-decoration: underline;
  }

  &__date {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
    align-self: center;
    margin-left: 12px;
    cursor: pointer;
    fill: $mine-shaft;
  }

  &__container--upload-mode {
    .folder-entry__filename:hover {
      color: #333;
      text-decoration: inherit;
    }

    .folder-entry__column {
      cursor: default;
    }
  }

}
