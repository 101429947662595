.portal-home {
  position: relative;

  &__actions {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  &__add-page,
  &__edit-page,
  &__add-block {
    color: #fff;
    margin-right: 10px;
  }

  &__add-block {
    margin-right: 0;
  }

  &__image:nth-last-child(2) {
    margin-bottom: 30px;
  }
}
