@import 'styles/breakpoints.scss';

.screen-selector {
  margin-top: 25px;

  &__text-box {
    margin-top: 25px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}