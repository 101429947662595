@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.profile {
  min-height: 300px;

  &__user-text {
    margin-left: 20px;
  }

  &__user-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__row {
    td {
      padding: 10px 0;

      &:first-child {
        font-weight: 600;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
          width: 150px;
        }
      }

      &:last-child {
        padding: 0 10px;
        word-break: break-word;
      }
    }
  }

  &__button-wrapper {
    margin-top: 40px;
    text-align: center;
  }
}
