@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.upload-entry {
  &__container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    padding: 2px 0 2px 7px;
    color: $mine-shaft;

    .upload-entry__filename:hover {
      color: #333;
      text-decoration: inherit;
    }

    .upload-entry__column {
      cursor: default;

      &--progress-bar {
        display: flex;
        width: 25%;
        align-items: center;
      }
    }

    &:hover {
      padding-left: 4px;
      border-left: 3px solid $blue90;
      background-color: $blue20;
    }

    &--upload-mode:hover {
      padding-left: 7px;
      border-left-width: 0;
      background-color: transparent;
    }
  }

  &__icon {
    width: auto;
    max-height: 30px;
  }

  &__column {
    display: flex;
    min-height: 35px;
    cursor: pointer;

    &:first-child {
      width: 20px;
      margin: auto 15px auto 0;
    }

    &:nth-child(2) {
      flex-grow: 1;
    }

    &:last-child {
      padding: 0 7px;
      margin-left: auto;
    }
  }

  &__icon,
  &__filename,
  &__size {
    display: inline-block;
    margin: auto 0;
    font-size: 14px;
  }

  // Override necessary since insights-listing-body-box__content *:nth-last-child(1) uses !important
  // with high specificity.
  &__icon.upload-entry__icon.upload-entry__icon.upload-entry__icon,
  &__filename.upload-entry__filename.upload-entry__filename.upload-entry__filename,
  &__size.upload-entry__size.upload-entry__size.upload-entry__size,
  &__date.upload-entry__date.upload-entry__date.upload-entry__date {
    margin-bottom: auto !important;
  }

  &__size {
    margin-right: 15px;
  }

  &__filename:hover {
    color: $blue90;
    text-decoration: underline;
  }

  &__date {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__close-icon {
    width: 16px;
    height: 16px;
    align-self: center;
    margin-left: 12px;
    cursor: pointer;
    fill: $mine-shaft;
  }
}
