@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.crossword {
  @include media-breakpoint-down(sm) {
    overflow-x: scroll;
  }

  &__container {
    min-width: 650px;
  }

  &__columns {
    max-width: 600px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      display: flex;
      max-width: none;
    }
  }

  &__content {
    margin-left: -15px;
    text-align: center;
  }

  &__content-row {
    display: flex;
  }

  &__content-button {
    margin: 60px 0 60px 60px;
  }

  &__content-headline {
    margin-bottom: 60px;
    margin-left: 15px;
    font-size: 18px;
    text-align: left;
  }

  &__list {
    width: 100%;
    list-style-type: none;
    padding-inline-start: 15px;

    @include media-breakpoint-up(lg) {
      margin-top: 5px;
      margin-left: 40px;
      border-left: 1px solid $dusty-gray;
      padding-inline-start: 30px;
    }
  }

  &__list-item {
    margin-bottom: 10px;

    &--focused {
      margin-bottom: 10px;
      background: #b4e6f2;
    }
  }

  &__question {
    display: flex;
    padding: 5px;
  }

  &__question-num {
    padding-right: 5px;
    color: $cerulean;
    font-weight: 600;
  }
}
