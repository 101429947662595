@import 'styles/colors.scss';

.toggle-button {
  margin: 0 5px 10px 5px;
  padding: 9px 20px;
  border-radius: 6px;
  border: solid 1px #ADADAD;
  appearance: none;
  cursor: pointer;
  font-family: 'Noto Sans Light', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;

  &--disabled {
    cursor: default;
    opacity: 0.5;
  }

  &__black {
    background: $white;
    color: #6C6C6C;

    &.toggle-button--selected {
      background-color: #007cab;
      color: $white;
      border: solid 1px #007cab;
    }
  }

  &__blue {
    background-color: #007cab;
    border-radius: 0;
    border: none;
    color: $white;
    padding: 10px 20px;
    margin: 0 20px 20px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &.toggle-button--selected {
      background-color: $white;
      color: #007cab;
      font-weight: 600;
      border: none;

    }
  }

  &__sharepoint-black {
    background-color: $black;
    border: none;
    color: $white;
    padding: 10px 20px;
    margin: 0 20px 20px 0;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}
