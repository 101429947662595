@import 'styles/breakpoints.scss';


.division-country-segment-selector {
  &__selectors {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .button {
      max-width: 100px;
    }

    > * {
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__select {
    width: 250px;
    
    @include media-breakpoint-up(sm) {
      padding-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
      width: 300px;
    }
  }
}
