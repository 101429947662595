@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.app-switch-item {
  display: flex;
  min-width: 13.125rem;
  height: 4.938rem;
  align-items: center;
  justify-content: center;
  background: $white;
  color: $brownish-grey;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.875rem;
  text-decoration: none;

  @include media-breakpoint-down(sm) {
    width: auto;
    justify-content: flex-start;
    padding: 0.938rem 4rem;
  }

  @include media-breakpoint-up(md) {
    height: 79px;
    min-width: fit-content;
    white-space: nowrap;
    padding: 15px 0;
  }

  &__text {
    @include media-breakpoint-up(md) {
      border-right: 0.125rem solid rgba(236, 236, 236, 0.8);
      text-align: center;
      padding: 0 15px;
    }
  }

  &--selected {
    width: max-content;
    color: $orient;
    font-size: 1rem;
    font-weight: bolder;
    pointer-events: none;
  }
}
