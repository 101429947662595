@import 'styles/colors.scss';

.load-more-button {
  display: inline-flex;
  border: 0;
  background: transparent;
  cursor: pointer;

  .icon--thin-arrow {
    width: 8px;
    height: 17px;
    margin-left: 15px;
    transform: rotate(90deg);

    .cls-1 {
      fill: none;
      stroke: $mine-shaft;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
    }
  }
}
