@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.editable {
  padding: 5px 10px;
  margin-bottom: 1.5rem;
  background-image: linear-gradient(to right, #333 50%, transparent 50%),
    linear-gradient(to right, #333 50%, transparent 50%),
    linear-gradient(to bottom, #333 50%, transparent 50%),
    linear-gradient(to bottom, #333 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px;

  // "min-width: 0" prevents the editable area from expanding in width
  // when a non-breakable word is too long (when wrapped in a flexbox)
  min-width: 0;

  &__hero {
    padding: 1px;
  }

  &--hashtag {
    display: flex;
    width: 100%;
    align-items: baseline;

    &::before {
      margin-right: 5px;
      content: '#';
      font-size: 1.75rem;
    }

    & > * {
      width: 100%;
    }
  }

  &--white {
    background-image: linear-gradient(to right, $white 50%, transparent 50%),
      linear-gradient(to right, $white 50%, transparent 50%),
      linear-gradient(to bottom, $white 50%, transparent 50%),
      linear-gradient(to bottom, $white 50%, transparent 50%);
  }

  &--solid-gray {
    border: 1px solid #cbcbcb;
    background-image: none;
  }

  &--error {
    border: 1px solid $guardsman-red;
    background-image: none;
  }

  &--rounded-corners {
    border-radius: 4px;
  }

  &--big {
    padding: 4px 9px 9px;
  }

  &--disabled {
    color: rgb(157, 157, 157);
  }

  .title & {
    margin-bottom: 0;
  }

  &-title {
    font-size: 1.5rem !important;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem !important;
    }
  }
  &-subtitle {
    font-size: 1.25rem !important;
    font-family: 'Noto Sans', sans-serif !important;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem !important;
    }
  }

  > *,
  .md-RichEditor-editor,
  .md-RichEditor-editor .public-DraftEditor-content,
  .md-RichEditor-editor .public-DraftEditorPlaceholder-root,
  .public-DraftEditorPlaceholder-root {
    padding: 0;
    margin: 0;
  }

  .public-DraftEditorPlaceholder-root {
    max-width: 100%;
  }

  .public-DraftEditorPlaceholder-inner {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  .md-RichEditor-root {
    padding: 0;
    line-height: 1.75;

    .public-DraftStyleDefault-ol,
    .public-DraftStyleDefault-ul {
      margin: 16px 7px;
    }

    .public-DraftStyleDefault-block {
      text-align: left;
    }

    h2 {
      margin: 32px 0 12px;
    }

    a {
      color: $blue;
    }

    .md-block-paragraph {
      margin: 0 0 1rem 0;
    }

  }

  .body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
  }
}