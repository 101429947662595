@import 'styles/breakpoints.scss';

.story-content{
  &__edit-translate {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &__translate {
    position: relative;
    right: -10px;
    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__container {
    margin: 0 auto;
    margin-top: 30px;
    
    @include media-breakpoint-up(md) {
      width: 70%;
    }
  }
  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
  }
  &__title {
    margin: 0 20px 5px;
    font-size: 24px !important;
    font-family: 'Noto Sans', sans-serif !important;
    @include media-breakpoint-up(md) {
      margin: 0 50px 5px 60px;
      font-size: 48px !important;
      width: 100%;
    }
  }
  &__language-wrapper {
    @include media-breakpoint-up(md) {
      width: 10%;
    }
  }
  &__subtitle {
    margin: 5px 20px 10px;
    font-size: 18px !important;
    font-family: 'Noto Sans', sans-serif !important;
    @include media-breakpoint-up(md) {
      margin: 0 60px 10px 60px;
      font-size: 20px !important;
      width: 75%;
    }
  }
  &__topic {
    color: #7e7e7e;
    font-family: 'Noto Sans';
    margin: 10px 20px 20px;
    font-size: 14px;
    line-height: 1.5;
    @include media-breakpoint-up(md) {
      margin: 10px 60px 60px 60px;
      font-size: 16px;
    }

    &__targeting {
      margin-top: 12px;
    }
  }
  &__readingtime {
    white-space: nowrap;
  }
  &__topic-link {
    text-decoration: none;
    color: #7e7e7e;
    &:hover {
      color: #a5a5a5;
    }
  }
  &__header-content {
    display: flex;
    justify-content: space-between;
  }
  &__preamble {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    color: #1a1a1a;
    font-size: 18px;
    margin: 20px 20px 20px 20px;
    font-family: 'Noto Sans';
    @include media-breakpoint-up(md) {
      margin: 60px 60px 12px 60px;
      font-size: 20px;
    }
  }
  &__image-gallery {
    margin: 0 20px;

    @include media-breakpoint-up(md) {
      margin: 0 60px;
    }
  }
  &__body {
    font-size: 14px;
    margin: 4px 20px 60px;
    font-family: 'Noto Sans';
    line-height: 1.5;

    h2 {
      margin: 32px 0 12px;
    }
    
    @include media-breakpoint-up(md) {
      margin: 30px 60px 80px 60px;
      font-size: 16px;
    }
  }
}
.story-first-tag {

  &__first-letter {
    position: relative;
    top: -22px;
    font-size: 75px;
    height: 58px;
    margin-right: 10px;
    float: left;
    
  }
  &__body{
    font-size: 16px;
    line-height: 1.5;
  }
}
.story-body {
  line-height: 1.5;
}
