@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.topics-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
  margin-top: 3%;
}

.topic-item {
  display: flex;
  width: 110%;
  height: 205px;

  @include media-breakpoint-up(tab) {
    width: 360px;
    background-image: linear-gradient(135deg, rgba($cerulean, 0.6), rgba(#034b70, 0.6)), url(./topic-desktop-fallback.png);
  }

  margin-bottom: 20px;
  position: relative;
  font-family: 'Noto Sans', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: white;
  background-size: cover;
  background-image: linear-gradient(135deg, rgba($cerulean, 0.6), rgba(#034b70, 0.6)), url(./topic-mobile-fallback.png);
}

@include media-breakpoint-up(tab) {
  .topic-item:hover {
    .topic-item-overlay {
      height: 100%;
    }
    .topic-item-title {
      top: 20%;
    }
    .topic-item-preamble {
      -webkit-animation: fadein 0.7s linear forwards; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 0.7s linear forwards; /* Firefox < 16 */
      -ms-animation: fadein 0.7s linear forwards; /* Internet Explorer */
      -o-animation: fadein 0.7s linear forwards; /* Opera < 12.1 */
      animation: fadein 0.7s linear forwards;
    }
    .topic-item-explore {
      -webkit-animation: fadein 0.7s linear forwards; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 0.7s linear forwards; /* Firefox < 16 */
      -ms-animation: fadein 0.7s linear forwards; /* Internet Explorer */
      -o-animation: fadein 0.7s linear forwards; /* Opera < 12.1 */
      animation: fadein 0.7s linear forwards;
    }
  }
}

.topic-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .7s ease;
}

.topic-item-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: .7s ease;
}

.topic-item-preamble {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  padding: 5px 30px;
  color: white;
  position: absolute;
  overflow: hidden;
  opacity: 0.2;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}

@keyframes fadein {
    0% { opacity: 0; }
    25% { opacity: 0.15; }
    50% { opacity: 0.35; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    25% { opacity: 0.15; }
    50% { opacity: 0.35; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    25% { opacity: 0.15; }
    50% { opacity: 0.35; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% { opacity: 0; }
    25% { opacity: 0.15; }
    50% { opacity: 0.35; }
    75% { opacity: 0.75; }
    100% { opacity: 1; }
}

.topic-item-explore {
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  width: 100%;
  color: white;
  position: absolute;
  overflow: hidden;
  opacity: 0.2;
  top: 67%;
  left: 50%;
  transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
}