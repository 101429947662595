@import 'styles/breakpoints.scss';

.footer-desktop {
  display: none;
  
  @include media-breakpoint-up(xl) {
    width: 1110px;
    margin: 3rem auto 2rem;
    display: flex;
    justify-content: space-between;
  }

  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  &__link-list {
    display: inline-block;

    &__header {
      display: block;
      margin: 0;
      font-weight: 100;
      margin-bottom: 0.75rem;
      font-size: 20px;
      font-family: 'Noto Sans', sans-serif;
    }
  }

  &__search {
    max-width: 250px;
    
    &__header {
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-weight: 100;
      font-size: 20px;
      font-family: 'Noto Sans', sans-serif;
    }

    &__link {
      text-decoration: none;
    }

    &__icon-container {
      max-width: 280px;
      height: 34px;
      border-radius: 8px;
      vertical-align: middle;
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 15px;
      
      &:hover {
        cursor: pointer;
      }

      p {
        color: #6d6c6c;
        line-height: 34px;
      }

      svg {
        margin: 4px 0;
        width: 20px;
      }
    }

    &__text {
      max-width: 250px;
      margin-top: 1.5rem;
      line-height: 26px;
      font-weight: 100;
    }
  }
}
