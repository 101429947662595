@import 'styles/colors.scss';

.mini-snackbar {
  &__list {
    position: absolute;
    z-index: 12;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
