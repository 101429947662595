@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.search-box {
  &__holder {
    position: relative;
    width: 100%;
    max-width: 730px;
    height: 40px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      margin: 0 auto 40px;
    }
  }

  &__field {
    width: calc(100% - 40px);
    height: 100%;
    padding: 6px 15px;
    border: none;
  }

  &__button {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 40px;
    height: 100%;
    border: none;
    background-color: $blue;
    vertical-align: top;

    &:hover {
      cursor: pointer;
    }
  }

  &__cancel-btn {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.001s ease-out;

    &--active {
      right: 40px;
      background-color: $white;
    }

    &:hover {
      background-color: $black20;
    }

    .icon--cancel {
      width: 16px;
      height: 16px;

      path {
        fill: $dusty-gray;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    fill: $white;
    transform: translate(-50%, -50%) !important;
  }
}
