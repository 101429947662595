@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.language-message {
  background-color: $white;

  &__wrapper {
    position: relative;
    width: 70%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__title {
    padding-right: 10px;
    color: #3f3f3f;
    font-family: 'Noto Sans', sans-serif;

    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
  }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &__description {
    width: 90%;
    color: #666;
    font-family: 'Noto Sans', sans-serif;

    @include media-breakpoint-up(md) {
      width: 670px;
    }
  }

  &__link {
    margin-left: 4px;
    color: $blue;
    cursor: pointer;
    text-decoration: underline;
  }
}
