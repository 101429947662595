@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.terms {
  width: 100%;
  padding: 20px;

  @include media-breakpoint-up(md) {
    min-height: 750px;
    padding: 20px 6rem 0;
  }

  &__preamble {
    font-size: 0.875rem;
  }

  table {
    td {
      padding: 10px;
      text-align: left;
      vertical-align: top;

      &:first-child {
        padding-left: 0;
      }
    }

    th {
      padding: 10px 10px 0 10px;
      border-bottom: 1px solid $gallery;
      text-align: left;
      vertical-align: bottom;

      p {
        font-weight: bold;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__text {
    max-height: 534px;
    overflow-y: scroll;
    text-align: left;

    p {
      font-size: 0.8rem;
    }
  }

  &__continue {
    display: block;
    margin: 0 auto;
  }

  + .modal__footer {
    min-height: 140px;
  }
}
