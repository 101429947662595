@import 'styles/breakpoints.scss';

.related-content-viewer {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__item {
    width: 98%;
    height: fit-content;
    margin: 0 6px 20px 0;
    background-image: linear-gradient(to bottom,  #005c8a, #008dbe);
    border-radius: 6px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      width: 330px;
      height: 164px;
      margin: 15px 40px 50px 0;
    }

    @include media-breakpoint-up(xxxl) {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  &__item:nth-child(2) {
    margin-top: 10px;

    @include media-breakpoint-up(lg) {
      margin: 15px 0 30px 15px;
    }

    @include media-breakpoint-up(xxxl) {
      margin-top: 15px;
    }
  }

  &__image-wrapper {
    display: none;
  }

  &__text-wrapper {
    padding: 16px 12px 16px 19px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    flex-direction: row;
    color: #fff;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.44;
  }

  &__description {
    margin: 10px 0 0;
    color: #fff;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.63;
  }

  &--vertical {
    flex-direction: column;

    .related-content-viewer__item {
      height: auto;
    }

    .related-content-viewer__item:nth-child(2) {
      margin: 0;
    }

    .related-content-viewer__image-wrapper {
      display: block;
    }

    .related-content-viewer__image {
      width: 100%;
    }
  }
}

.chevron-right {
  margin: 0 5px;
  margin-top: 5px;
}
