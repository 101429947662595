@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.insights-database-topics-box {
  max-width: 1110px;
  text-align: center;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    margin-top: -30px;
  }

  &__block {
    margin: 0 0 60px;

    @include media-breakpoint-down(sm) {
      padding: 30px 25px;
      border-bottom: 1px solid $gallery;
      margin: 0 -25px;
    }
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
    }
  }

  &__title {
    font-family: 'Noto Sans', sans-serif;
    font-size: 28px;
    line-height: 32px;
    color: $mine-shaft;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 35px;
    }
  }

  &__main-link {
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    color: $blue;
    margin: 0 0 0 30px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__main-link-mobile {
    display: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      display: inline;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0 -15px;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__item {
    width: 33.33%;
    padding: 0 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__item-link {
    text-decoration: none;
  }

  &__image-holder {
    margin: 0 0 10px;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__date-panel {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 10px;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      line-height: 17px;
    }
  }

  &__type {
    color: $mine-shaft;
  }

  &__date {
    color: $blue;
  }

  &__content-loader {
    padding-bottom: 50px;

    &__item {
      padding: 0.625rem 1.875rem;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;

      &__item {
        width: 100%;
      }
    }
  }

  &__subtitle {
    display: block;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    color: $mine-shaft;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__likes-and-comments {
    display: flex;
    justify-content: right;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }
}
