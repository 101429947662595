@import 'styles/colors.scss';

.error-message {
  &__text-wrapper {
    margin-top: 4px;
    margin-left: 10px;
  }

  &__title {
    margin-bottom: 10px;
    color: $signal-red;
  }

  &__text {
    margin: 0;
  }

  &--small {
    display: block;

    .error-message__text-wrapper {
      margin-left: 0;
    }
  }

  display: block;
  display: flex;
  flex: 1;
  padding: 14px 17px 20px;
  margin: 10px;
  border: 1px solid $signal-red;
  background: white;
  color: black;
  font-size: 16px;
  text-align: left;
}
