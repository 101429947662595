@import 'styles/colors.scss';

.sharepoint-item {
    border-bottom: 1px solid $gallery;
    margin-top: 24px;
    
    &__label {
        color: $job-search-text;
        font-size: 0.875rem;
        margin-bottom: 12px;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $near-bondi-blue;
    }

    &__title, &__summary {
        font-family: 'Noto Sans', sans-serif !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.2 !important;
        margin-bottom: 8px;
    }

    &__owner {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
}