.sort-order {
  display: flex;
  flex-direction: row;
  align-items: center;

  .editable {
    display: flex;
    width: 200px;
    flex: 1 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 15px 9px;
    margin-bottom: 0;

    p {
      margin: 0;
    }
  }

  input {
    width: 75px;
    padding: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  span {
    margin-left: 20px;
    color: #666;
    font-size: 0.8em;
  }
}
