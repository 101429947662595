@import 'styles/colors.scss';
@import 'styles/helpers.scss';

.select {
  @include custom-scrollbar($gallery, $white);

  position: relative;
  width: 100%;
  max-width: 100%;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--global-selected {
    li:not(:first-child) {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--division-selected {
    li:first-child {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: bold;
  }

  &__inner {
    padding: 0 10px;
    border: 1px solid #d6d6d6;
    border-bottom-color: $black;
    background: $white;

    .select--ghost & {
      border-color: $white;
      background: none;
      border-radius: 4px;
      color: $white;
    }

    .select--open & {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select--error & {
      border-color: $guardsman-red;
    }
  }

  &--black {
    .select__inner {
      border: 1px solid $text-color;
      color: $text-color;
    }
  }

  &__label {
    position: relative;
    overflow: hidden;
    padding-right: 20px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.9;
    white-space: nowrap;

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      padding: 0 8px;
      background: $white;

      .select--ghost & {
        background: none;
      }
    }

    .icon {
      width: 12px;
      height: 12px;
      transition: transform 200ms ease;

      .select--ghost & {
        fill: $blue;
      }

      .select--open & {
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    z-index: 4;
    right: 0;
    left: 0;
    overflow: auto;
    max-height: 262px;
    border: 1px solid $black;
    background: $white;
    
    .select--ghost & {
      border-color: $white;
      background: #034b70;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    ul {
      max-height: 500px;
      padding-left: 0;
      margin: 2px 0;
    }
  }

  &__option {
    padding: 5px 10px;
    list-style: none;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--depth1 {
      padding-left: 20px;
    }


    input {
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-right: 13px;
    }

  }
 
}
