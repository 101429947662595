@import 'styles/breakpoints.scss';

.mobile-only {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.mobile-tablet-only {
  display: block;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;

    &--inline {
      display: inline-block;
    }
  }
}
