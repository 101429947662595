@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';
@import 'styles/colors.scss';

.header-box {
  position: relative;
  padding: 36px 24px 60px;
  background-color: $orient;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: white;
  text-align: center;

  &--searching {
    padding-bottom: 200px;
  }

  @include media-breakpoint-up(md) {
    padding: 60px 30px 60px;

    &--searching {
      padding-bottom: 200px;
    }
  }

  &__background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__background-inner {
    position: relative;

    &--full-height {
      height: 100%;
    }

    img {
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        min-height: 400px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS */
          min-width: 600px;
        }
      }
    }
  }

  &__background-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $gradient;
  }

  &__background-pink-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $pink-gradient;
  }

  &__background-green-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $green-gradient;
  }

  &__background-red-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $red-gradient;
  }

  &__content {
    position: relative;
    max-width: 670px;
    margin: 0 auto 40px auto;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }

  &__container {

    .doc-box {
      position: relative;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-family: 'Noto Sans Light', sans-serif !important;
    font-size: 28px !important;

    @include media-breakpoint-up(md) {
      font-size: 58px !important;
    }
  }

  &__preamble,
  &__pre-text {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &__pre-text {
    text-transform: uppercase;
  }

  .column {
    padding: 0;
  }
}
