@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.library-menu {
  display: flex;
  width: 100%;
  height: 75px;
  padding: 0;
  margin-bottom: 0;

  @include media-breakpoint-up(xl) {
    width: 360px;
  }

  @include media-breakpoint-up(xxxl) {
    width: 345.5px;
  }

  &__item {
    height: inherit;
    flex: auto;
    border-right: 1px solid $near-bondi-blue;
    background: #005c8a;
    font-family: 'Noto Sans', sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.01px;
    list-style: none;

    &:last-child {
      border-right: none;
    }

    a,
    div {
      display: flex;
      height: inherit;
      flex: auto;
      align-items: center;
      justify-content: center;
      color: $white;
      cursor: pointer;
      font-size: 0.875rem;
      text-decoration: none;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }

      &.active {
        background:  $near-bondi-blue;
        font-weight: bold;
      }
    }
  }
}
