@font-face {
  font-family: 'ASSA Vesta Light';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/aaveslgt-webfont.eot'),
    url('../assets/fonts/aaveslgt-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/aaveslgt-webfont.woff') format('woff'),
    url('../assets/fonts/aaveslgt-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'ASSA Vesta Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/aavesreg-webfont.eot'),
    url('../assets/fonts/aavesreg-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/aavesreg-webfont.woff') format('woff'),
    url('../assets/fonts/aavesreg-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'ASSA Vesta Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/aavesreg-bold.eot'),
    url('../assets/fonts/aavesreg-bold.woff') format('woff'),
    url('../assets/fonts/aavesreg-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Light';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'ASSA Sans';
  src: url('../assets/fonts/ASSASans-Variable.woff2') format('woff2'),
       url('../assets/fonts/ASSASans-Variable.woff') format('woff');
  font-weight: 100 900;
  font-style: normal;
}