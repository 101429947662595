@import 'styles/breakpoints.scss';

.flex {
  display: flex;
  align-items: center;

  &--responsive {
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }

  &__direction-row {
    flex-direction: row;
  }

  &__direction-column {
    flex-direction: column;
  }

  &__space-between {
    justify-content: space-between;
  }

  &__space-around {
    justify-content: space-around;
  }

  &__flex-end {
    justify-content: flex-end;
  }

  &__center {
    justify-content: center;
  }

  &__align-start {
    align-items: flex-start;
  }

  &__align-end {
    align-items: flex-end;
  }

  &-wrap {
    &--nowrap {
      flex-wrap: nowrap;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }
}
