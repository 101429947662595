@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.mosaic-link--card-style {
  &.mosaic-link {
    position: relative;
    min-width: calc(100% - 10px);
    margin: 0 10px 10px 10px;
    text-align: left;
    background: #034B70;
  }

  .mosaic-link {
    &__image-container {
      width: 120px;
      height: 100%;
      flex-shrink: 0;
      object-fit: cover;
    }

    &__text-container {
      width: 100%;
      height: 100%;
      padding: 10px 25px;
      background: white;
      color: $text-color;
      transition: all 0.15s ease-in-out;
    }
    
    &__title {
        margin-top: 0;
        margin-bottom: 10px;
        font-family: 'Noto Sans', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
    }

    &__preamble {
      margin: 10px 0 0 0;
      font-family: 'Noto Sans', sans-serif;
      font-size: 12px;
      line-height: 18px;

      &--regular-line-clamp {
        display: none;
      }
    }
  }

  a {
    display: flex;
    height: 100%;
    flex-grow: 1;
    text-decoration: none;

    &:hover {
      &, * {
        text-decoration: underline;
      }

      .mosaic-link__image-container {
        opacity: 0.85;
      }
    }
  }
}

@include media-breakpoint-up(xs) {
  .mosaic-link--card-style {
    .mosaic-link {
      &__image-container {
        width: 150px;
        height: 100%;
      }

      &__preamble {
        &--reduced-line-clamp {
          display: none;
        }

        &--regular-line-clamp {
          // Fallback for IE11.
          display: block;
          display: initial;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .mosaic-link--card-style {
    &.mosaic-link {
      min-width: 539px;
      max-width: 539px;
      margin: 0 10px 20px 10px;
    }

    .mosaic-link {
      &__image-container {
        width: 200px;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .mosaic-link--card-style {
    &.mosaic-link {
      min-width: 539px;
      max-width: 539px;
      margin: 0 16px 30px 16px;
    }

    .mosaic-link {
      &__text-container {
        padding: 17px 15px 24px 15px;
      }

      &__image-container {
        height: 100%;
      }

      &__title {
        font-size: 22px;
        line-height: 35px;
      }

      &__preamble {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.mosaic-link--mosaic-style {
  &.mosaic-link {
    position: relative;
    min-width: 274px;
    max-width: 274px;
    margin: 0 0 10px 0;
    background: #034B70;
  }

  .mosaic-link {
    &__image-container {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }

    &__text-container {
      position: relative;
      width: 100%;
      flex-grow: 1;
      padding: 10px 25px;
      background: white;
      color: $text-color;
      text-align: left;
      transition: all 0.15s ease-in-out;
    }
    &__heading {
      margin: 0;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 3px;
      font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }

    &__preamble {
      margin: 0;
      font-family: 'Noto Sans', sans-serif;
      font-size: 12px;
      line-height: 18px;

      &--reduced-line-clamp {
        display: none;
      }
    }
  }

  a {
    display: flex;
    height: 100%;
    flex-direction: column;
    text-decoration: none;

    &:hover {
      text-decoration: underline;

      .mosaic-link__image-container {
        opacity: 0.85;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .mosaic-link--mosaic-style {
    &.mosaic-link {
      min-width: 320px;
      max-width: 320px;
      margin: 0 10px 20px 10px;
    }

    .mosaic-link__image-container {
      height: 180px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .mosaic-link--mosaic-style {
    &.mosaic-link {
      min-width: 350px;
      max-width: 350px;
      margin: 0 16px 30px 16px;
    }

    .mosaic-link {
      &__text-container {
        padding: 17px 15px 24px 15px;
      }

      &__image-container {
        height: 200px;
      }


      &__title {
        font-size: 22px;
        line-height: 35px;
      }

      &__preamble {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
