@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.app-switch-select {
  display: flex;
  align-items: center;

  &__link {
    svg,
    svg.icon--logo {
      width: 32px;
      height: 32px;
    }

    @include media-breakpoint-down(sm) {
      text-decoration: none;
      color: #034b70;
      font-family: 'Noto Sans', sans-serif;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }

    @include media-breakpoint-up(xl) {
      svg,
      svg.icon--logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  &__text {
    cursor: pointer;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    color: #034b70;
    display: none;
    align-items: center;
    font-weight: 600;

    &:after {
      content: ' ';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #333333 transparent transparent transparent;
      margin-left: 5px;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
    }
  }

  &--nav-opened {
    .app-switch-select__link {
      display: none;
    }
    .app-switch-select__text {
      display: flex;
    }
  }
}
