@import 'styles/colors.scss';

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.4;
  user-select: none;

  input {
    position: relative;
    width: 12px;
    height: 12px;
    margin-top: 2px;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }


    &::before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 14px;
      height: 14px;
      border: 1px solid $black;
      background: $white;
      border-radius: 2px;
      content: '';

      .select--ghost & {
        border-color: $white;
        background: #034b70;
      }
    }

    &::after {
      position: absolute;
      top: -8px;
      left: 1px;
      width: 15px;
      height: 19px;
      background: url('./check.svg') no-repeat;
      content: '';
      transform: scale(0);

      .select--ghost & {
        background: url('./check-white.svg') no-repeat;
      }
    }

    &:checked::after,
    .select__option--checked &::after {
      transform: scale(1);
      transition: all 200ms ease-in-out;
    }

    &[type='checkbox']:indeterminate::after {
      top: 4px;
      right: 0;
      bottom: 0;
      width: 8px;
      height: 2px;
      background: black;
      transform: none;
      transition: scale 0s;
    }
  }

  &--color-gray input::before {
    background: #f4f4f4;
  }

  &--color-disabled input::before {
    border-color: #d6d1d1;
  }
}
