@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.carousel {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  .icon--thin-arrow {
    width: 13px;
    height: 40px;

    .cls-1 {
      fill: none;
      stroke: $mine-shaft;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 0.75px;
    }
  }

  &__control {
    position: absolute;
    top: 50%;
    padding: 10px;
    border: none;
    appearance: none;
    background: none;
    cursor: pointer;
    transform: translateY(-50%);

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    &--prev {
      left: 10px;
      padding-left: 0;

      .icon {
        transform: rotate(180deg);
      }

      @include media-breakpoint-up(md) {
        left: 60px;
      }
    }

    &--next {
      right: 10px;
      padding-right: 0;

      @include media-breakpoint-up(md) {
        right: 60px;
      }
    }
  }

  &__legend {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    &-item {
      width: 18px;
      height: 2px;
      margin-right: 6px;
      border: none;
      appearance: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0;

      &--prev {
        background-color: $mine-shaft;
      }

      &--next {
        background-color: #919191;
        cursor: default;
      }

      &--visited {
        background-color: $mine-shaft;
        cursor: pointer;
      }

      &--current {
        background-color: $cerulean;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__stage {
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
  }

  &__items {
    display: flex;
    height: 100%;
    flex-grow: 1;
    margin: 20px auto 0;
    transition: transform 300ms ease-in-out;
  }

  &__item {
    display: flex;
  }

  &__item-inner {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      padding: 0 94px;
    }
  }
}
