@import 'styles/breakpoints.scss';

.info-box {
  position: relative;
  margin-left: 10px;
  margin-top: 3px;

  &__message-box {
    position: absolute;
    display: inline-block;
    top: 35px;
    left: -270px;
    width: 320px;
    font-size: 0.8em;
    background-color: white;
    padding: 20px;
    z-index: 100;
    box-shadow: 0 2px 8px rgba(9, 88, 112, 0.08);

    @include media-breakpoint-down(sm) {
      left: inherit;
      right: -20px;
    }
  }
}