@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

$topLevelBackground: $near-bondi-blue;

.tree-item {
  list-style: none;

  + .tree-item {
    .tree-item__container {
      border-top: 1px solid #cbcbcb;
    }
  }

  &__container {
    position: relative;
    display: block;
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 63px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  a, .tree-item__nolink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px 20px 0;
    background-color: $white;
    color: $orient;
    line-height: 1.3rem;
    overflow-wrap: break-word;
    text-decoration: none;
    word-break: break-word;
    word-wrap: break-word;
  }

  .icon--arrow {
    width: 18px;
    height: 18px;
    margin-bottom: -3px;
    fill: $orient;
    transform: rotate(-90deg);
    transition: transform 100ms linear;
  }

  &--depth-1 {
    span {
      z-index: 7;
    }

    a, .tree-item__nolink {
      padding-left: 15px;
      background-color: $near-bondi-blue;
      color: $white;
    }

    .icon--arrow {
      fill: $white;
    }


    &__portal {
      span {
        z-index: 7;
      }
      
      a {
        background-color: $white-lilac;
        color: $medium-grey;
      }

      .icon--arrow {
        fill: $medium-grey;
      }
    }
  }

  &--depth-2 {
    span {
      z-index: 6;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }


    &__portal {
      span {
        z-index: 6;
      }

      a {
        color: $black;
      }

      .icon--arrow {
        fill: $black;
      }
    }
  }

  &--depth-3 {
    border-left: 5px solid $topLevelBackground;

    span {
      z-index: 5;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }


    &__portal {
      border-left: 5px solid $topLevelBackground;

      span {
        z-index: 5;
      }

      a {
        padding-left: 30px;
      }
    }
  }

  &--depth-4 {
    border-left: 10px solid $topLevelBackground;

    span {
      z-index: 4;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }

    
    &__portal {
      border-left: 10px solid $topLevelBackground;

      span {
        z-index: 4;
      }

      a {
        padding-left: 30px;
      }
    }
  }

  &--depth-5 {
    border-left: 15px solid $topLevelBackground;

    span {
      z-index: 3;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }

    &__portal {
      border-left: 15px solid $topLevelBackground;

      span {
        z-index: 3;
      }

      a {
        padding-left: 30px;
      }
    }
  }

  &--depth-6 {
    border-left: 20px solid $topLevelBackground;

    span {
      z-index: 2;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }

    &__portal {
      border-left: 20px solid $topLevelBackground;

      span {
        z-index: 2;
      }

      a {
        padding-left: 30px;
      }
    }
  }

  &--depth-7 {
    border-left: 25px solid $topLevelBackground;

    span {
      z-index: 1;
    }

    a, .tree-item__nolink  {
      padding-left: 30px;
    }

    &__portal {
      border-left: 25px solid $topLevelBackground;

      span {
        z-index: 1;
      }

      a {
        padding-left: 30px;
      }
    }
  }

  &--active {
    a {
      background: #e1f6fa;
      color: $orient;
    }

    .icon--arrow {
      fill: $orient;
    }


    &__portal {
      a {
        background: #e1f6fa;
        color: $orient;
      }
  
      .icon--arrow {
        fill: $orient;
      }
    }
  }

  &--inActivePath {
    a {
      font-weight: 700;
    }

    &__portal {
      a {
        font-weight: 700;
      }
    }
  }

  &--open {
    > span {
      box-shadow: 0 2px 5px rgba(63, 63, 63, 0.32);
    }

    .icon--arrow {
      transform: rotate(0deg);
    }
    
    &__portal  {
      > span {
        box-shadow: 0 2px 5px rgba(63, 63, 63, 0.32);
      }
  
      .icon--arrow {
        transform: rotate(0deg);
      }
    }
  }
}
