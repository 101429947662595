@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
$sides: (
  '': 'all',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right'
);

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.relative {
  position: relative;
}

.size-small {
  font-size: 0.85rem;
}

.mobile-hidden {
  display: none;

  @include media-breakpoint-up(md) {
    display: inherit;
  }
}

.desktop-hidden {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .sm-bg-blue {
    background-color: $blue;
  }
}

.bg-blue {
  background-color: $blue;
}

.max-width {
  max-width: 670px;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: 600;
}

.display-inline-block {
  display: inline-block;
}

.list-style-none {
  list-style-type: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

@include media-breakpoint-down(md) {
  .mobile-center {
    text-align: center;
  }

  .mobile-display-block {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .sm-position-absolute {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
  }
}

@include media-breakpoint-up(md) {
  .desktop-text-right {
    text-align: right;
  }
}

.m-0-a {
  margin: 0 auto;
}

@each $prefix, $value in $sides {
  $property: if($prefix== '', '', -#{$value});

  .m-#{$prefix}-huge {
    margin#{$property}: 150px !important;
  }
}

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix== '', '', -#{$value});

    .m-#{$prefix}-#{$space} {
      margin#{$property}: #{$space * 5}px !important;
    }

    .p-#{$prefix}-#{$space} {
      padding#{$property}: #{$space * 5}px !important;
    }

    .b-#{$prefix}-#{$space} {
      border#{$property}: #{$space}px solid $gallery !important;
    }
  }
}

@include media-breakpoint-down(md) {
  @each $space in $spaceamounts {
    @each $prefix, $value in $sides {
      $property: if($prefix== '', '', -#{$value});

      .sm-m-#{$prefix}-#{$space} {
        margin#{$property}: #{$space * 5}px !important;
      }

      .sm-p-#{$prefix}-#{$space} {
        padding#{$property}: #{$space * 5}px !important;
      }

      .sm-b-#{$prefix}-#{$space} {
        border#{$property}: #{$space}px solid $gallery !important;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  @each $space in $spaceamounts {
    @each $prefix, $value in $sides {
      $property: if($prefix== '', '', -#{$value});

      .lg-m-#{$prefix}-#{$space} {
        margin#{$property}: #{$space * 5}px !important;
      }

      .lg-p-#{$prefix}-#{$space} {
        padding#{$property}: #{$space * 5}px !important;
      }

      .lg-b-#{$prefix}-#{$space} {
        border#{$property}: #{$space}px solid $gallery !important;
      }
    }
  }
}

.visibly-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.vh-100 {
  height: 100vh;
}

@include media-breakpoint-down(sm) {
  .sm-vh-100 {
    height: 100vh;
  }
}

@mixin if-ie {
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin custom-scrollbar($track, $thumb) {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background: $track;
  }

  ::-webkit-scrollbar-thumb {
    border: 5px solid $track;
    background: $thumb;
    border-radius: 10px;
  }
}

.ie-flex-fix {
  min-height: 1px;
}
