@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.nav--border {
  border-bottom: 1px solid #006495;
}

.navigation {
  &__overflow {
    height: 60px;
    max-height: 60px;

    [data-overflow-viewport] {
      // Use of !important since component does not expose an option for
      // overflow appearance styling.
      overflow-y: hidden !important;

      // For Safari and Chrome 
      &::-webkit-scrollbar {
        display: none;
      }
      // For IE
      -ms-overflow-style: none;
      // For Firefox
      scrollbar-width: none;
    }
  }

  &__scroll-button {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      z-index: 10;
      right: -15px;
      bottom: 5px;
      width: 32px;
      height: 44px;
      border: none;
      background-color: #074E74;

      :hover {
        cursor: pointer;
      }

      svg {
        > path {
          fill: $people-search-background;
        }
        
        g > path {
          fill: $people-search-background;
        }
      }
    }
  }

  &__inner {
    position: absolute;
    z-index: 11;
    display: none;
    width: 100%;

    @include media-breakpoint-up(md) {
      position: static;
      display: block;
    }

    &--empty {
      height: 58px;
    }
  }

  &__tools {
    position: absolute;
    z-index: 12;
    top: 0;
    right: 0;
  }

  &__items-wrapper {

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: calc(100% + 2rem);
      height: 60px;
      padding-right: 5px;
      height: 60px;
    }
  }

  &__items {
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      padding: 0;
      margin: 0;
    }

    &--bottom-group {
      background: $white;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__item-divider {
    padding: 0;
    border-color: #006495;
    margin: 5px 15px;

    @include media-breakpoint-up(md) {
      display: block;
      width: 1px;
      padding: 18px 0;
      border-color: #006495;
      margin: 0 10px;
      margin-top: 13px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 0 -15px;
    background: $white;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__details {
    margin-left: 20px;
  }

  &__name,
  &__location {
    display: block;
  }

  &__name {
    color: $text-color;
    font-size: 0.875rem;
    line-height: 1.2rem;
  }

  &__location {
    color: $dusty-gray;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &--mobile {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
