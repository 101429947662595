@import 'styles/colors.scss';

.next-article {
  &__label {
    color: $semi-black;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 1.125rem;
    line-height: 1.44;
  }

  &__icon {
    path {
       fill : $cerulean;
    }
  }
}