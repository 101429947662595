.no-result {
  font-size: 18px;

  &__item {
    color: #5e5e5e;
  }

  &__info-title {
    font-family: 'Noto Sans';
    font-size: 18px;
  }
}

.custom-ul {
  li {
    font-weight: normal;

    &::before {
      width: 8px;
      height: 8px;
    }
  }
}
