@import 'styles/colors.scss';

.text-label {
  display: inline-block;
  min-width: 41px !important;
  padding: 6px 9px 7px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 0.75rem;
  line-height: 1.15;
  
  &--square {
    border-radius: 0;
  }

  &--blue {
    border-color: #e1f6fa;
    background-color: #e1f6fa;
    color: $cerulean;

    a {
      color: $cerulean;
    }
  }

  &--deep-blue {
    border-color: $orient;
    background-color: $orient;
    color: #fafafa;

    a {
      color: #fafafa;
    }
  }

  &--white {
    border-color: $orient;
    background-color: #fafafa;
    color:  $orient;
  }

  &--black {
    border-color: $text-color;
    background-color: $text-color;
    color: #fafafa;
  }

  &--orange {
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    border-color: #ff8b64;
    background-color: #ff8b64;
    color: #fafafa;
  }

  &--ghost {
    &.text-label--blue {
      border-color: #b4e6f2;
      background: transparent;
      color: $cerulean;
    }
  }

  &--transparent {
    padding: 0;
    border: 0;
    background: transparent;
    color: $cerulean;
    font-size: 0.75rem;
  }

  &--dusty-gray {
    border-color: $trolley-grey;
    background-color: $trolley-grey;
    color: #fafafa;
  }

  &--transparent-gray {
    border-color: $mine-shaft;
    background-color: transparent;
    color: $mine-shaft;
  }

  &--gray {
    padding: 0;
    border: 0;
    background: transparent;
    color: $dark-gray;
    font-size: 10px;
    opacity: 0.7;
  }

  &--big {
    padding: 0.4375rem 0.6875rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &--block {
    display: block;
  }

  &--flex {
    display: flex;
  }

  &--bold {
    font-weight: 600;
  }

  &--light {
    font-weight: 400;
  }

  &--search-blue {
    color: $search-blue;
    min-width: 0 !important;
  }

  &--label-no-top-margin {
    margin-top: 0 !important;
  }

  &--text-only {
    color: $job-search-text;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-right: 1px solid #1a1a1a;
    border-radius: 0;
  }

  span {
    display: block;
  }
}
