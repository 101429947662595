@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.sorting-control {
  position: relative;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 15px;

  &.mobile {
    display: flex;
    margin-bottom: 0;
    background-color: $white;
  }

  &__order {
    svg {
      filter: invert(100%);
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    color: $white;


    &.mobile {
      display: none;
    }

    &__order {
      svg {
        filter: none;
      }
    }
  }

  &__label,
  &__type {
    margin-right: 5px;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    white-space: nowrap;
  }

  &__type {
    font-weight: bold;
    text-decoration: underline;
  }

  &__order {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 3px;
    position: relative;

    &:hover {
      background-color: $black20;
    }

    &:active {
      background-color: $black30;
    }

    &--asc {
      transform: scaleY(-1);
    }
  }

  &__options {
    position: absolute;
    top: 5px;
    min-width: 100px;
    right: 30px;
    z-index: 1;
    padding: 5px 0;
    margin: 0;
    list-style-type: none;
    background-color: $white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    color: $mine-shaft;

    li {
      padding: 5px 5px;
      cursor: pointer;
      font-size: 14px;

      &:hover,
      &.active {
        background-color: $gallery;
      }
    }
  }
}

.insights-sorting {
  display: flex;
  color: black;
  margin: 0;
  svg {
    filter: invert(100%) !important;
  }  
}
