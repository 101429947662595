@import 'styles/colors.scss';

.accordion {
  &-container {
    border-bottom: 1px solid #D9D9D9;
    user-select: none;
    cursor: default;
    display: flex;
    
    &-upper-row {
      flex-grow: 2;
      
      &--clickable {
        cursor: pointer;
        padding: 0 21px 12px 16px;
      }
      
      &--wrapper {
        display: flex;
        justify-content: space-between;
      }
      
      &--icon {
        display: flex;
        align-items: center;
      }
    }

    &__body {
      padding: 16px 21px 16px;

      img {
        width: auto !important;
      }
    }

    &-header-row {
      display: flex;
      color: #125468;

      &__number {
        background: $icon-grey;
        padding: 3px 10px;
        border-radius: 50px;
        margin: 0 14px 0 0 !important;
        
        font-family: 'Noto Sans' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
      }
      
      &__title {
        margin: 0 !important;
        
        font-family: 'Noto Sans' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 27px !important;
      }
    }

    &--edit {
      cursor: pointer;

      &--icon {
        width: 30px;
        height: 30px;

        path {
          stroke: rgb(18, 84, 104);
        }
      }
    }    
  }
}
