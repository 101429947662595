@import 'styles/breakpoints.scss';

.portal-search-form {
  position: relative;
  max-width: 670px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ffffff;

  &__query {
    width: 100%;
    height: 35px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;
    margin-right: 10px;

    &::placeholder {
      color: #ffffff;
    }
  }

  &__submit {
    height: 21px;
    width: 21px;

    path {
      fill: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @include media-breakpoint-up(md) {
    &__query {
      font-size: 23px;
      height: 64px;
    }

    &__submit {
      height: 43px;
      width: 43px;
    }
  }
}
