@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.news__wizard {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 0 24px;
  gap: 16px;
  width: 400px;

  &--circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    background: rgba(242, 247, 249, 0.7);
    width: 36px;
    height: 34px;

    &--number {
      display: flex;
      justify-content: center;
      color: #125468;
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    &--active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-width: 1px;
      border-style: solid;
      border-radius: 50px;
      background: $icon-grey;
      width: 36px;
      height: 34px;
    }
  }

  &--text {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.7);

    &--active {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: $white;
    }
  }
}

.article-editor {
  &__content {
    display: none;

    &--active {
      display: block;
    }

    &__button {
      display: flex;
      flex-direction: row-reverse;
  
      &--next {
        display: flex;
        align-items: center;
        padding: 8px 32px;
        gap: 8px;
        width: 102px;
        height: 40px;
        background: $orient;
        border-radius: 4px;
        border-color: transparent;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        cursor: pointer;
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__targetingview {
    display: none;

    &--active {
      display: block;
    }

    &__button {
      padding-bottom: 16px;

      &--back {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        background-color: transparent;
        width: 93px;
        height: 37px;
        border: 1px solid $orient;
        border-radius: 4px;
        cursor: pointer;
        color: $orient;
      }
    }

    &--select {

      &-topic {
        margin: 0 0 16px;
      }
      
      &-container {
        padding-bottom: 8px; 
  
        .radio {
          cursor: default; 

          &__input {

            &:checked + label::before {
              border: 2px solid $cerulean;
            }

            &:checked + label::after {
              border: 2px solid $cerulean;
              background: $cerulean;
            }
          }
  
          &__label {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;

            &::before {
              border: 2px solid #797979;
            }
            &::after {
              border: 2px solid $cerulean;
            }

            &--checked {
              color: $orient;
            }
          }
        }
      }
    }

    &--channels {
      font-family: 'Noto Sans';
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: $semi-black;
    }

    &--checkbox {
      display: flex;
      flex-direction: column;

      &--featurednews {
        display: flex;
      }

      .screen-selector {
        margin: 0;
      }
    }

    .article-button--wrapper {
      display: flex;
      flex-flow: row-reverse nowrap;
      gap: 16px;
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &--publish {
        padding: 8px 24px;
        background: $orient;
        border-radius: 4px;
      }

      &--saveasdraft {
        padding: 8px 24px;
        border-radius: 4px;
        border: 1px solid $orient;
        background-color: $white;
        color: $orient;
      }

      &--cancel {
        padding: 8px;
        border-color: transparent;
        color: $orient;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }

      &--delete {
        padding: 8px;
        border-color: transparent;
        color: $orient;
      }
    }
  }
  
  @include media-breakpoint-up(md) {
    &__content-container--portrait-mode {
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: space-between;
  
      .article-editor {
        &__hero-content,
        &__text-content {
          flex: 0 0 calc(50% - 12px);
        }
        
        &__content-wrapper{
          display: flex;
          justify-content: space-between;
          flex-flow: column wrap;
          min-width: 300px;
          flex: 0 0 calc(50% - 12px);
        }
      }
    }

    &__hero-content,
    &__text-content {
      flex: 0 0 calc(66% - 12px);
    }

    &__content-wrapper{
      display: flex;
      justify-content: space-between;
      > div {
        // "min-width: 0" prevents the flex children from expanding 
        // in width when a non-breakable word is too long
        min-width: 0;
      }
    }

    &__good-to-know{
      flex: 0 0 calc(33% - 12px);
      flex-flow: column nowrap;
      font-size: 14px;
    }
  }
}