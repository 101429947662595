@import 'styles/breakpoints.scss';

.strategy-house{
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: $green-gradient;

  &__container {
    position: relative;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    background: url('./strategy-portal-background.jpg') no-repeat center;
    height: 100%;
    z-index: -1;
    width: 100%;
    
    @include media-breakpoint-up(md) {
      background: url('./strategy-portal-background.jpg') no-repeat;
      background-size: cover;
    }
  }

}