@import 'styles/colors.scss';

$radio-outer-size: 15px;
$radio-inner-size: 7px;
$radio-styled-overlay-top: 1px;

.radio {
  position: relative;
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;

  &__input {
    width: $radio-outer-size;
    height: $radio-outer-size;
    visibility: hidden;
  }

  &__label {
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;

    &::before {
      position: absolute;
      top: $radio-styled-overlay-top;
      left: 0;
      width: $radio-outer-size;
      height: $radio-outer-size;
      border: 1px solid $black;
      border-radius: 50%;
      content: '';
    }

    &::after {
      position: absolute;
      top: $radio-styled-overlay-top + (
        $radio-outer-size - $radio-inner-size
      ) / 2;
      left: ($radio-outer-size - $radio-inner-size) / 2;
      width: $radio-inner-size;
      height: $radio-inner-size;
      background-color: $black;
      border-radius: 50%;
      content: '';
      transform: scale(0);
      transition: transform 200ms;
    }
  }

  input:checked + label::after {
    transform: scale(1);
  }
}
