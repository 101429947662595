@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.gateway-block {
  padding: 60px 24px;
  background: $orient;
  color: $white;

  &__list-wrapper {
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__list {
    display: flex;
    overflow: hidden; // To prevent vertical scrollbar in mobile
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
