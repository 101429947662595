@import 'styles/colors.scss';

.ellipsis-menu {
  &__wrapper {
    position: relative;
  }

  &__container {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    padding: 7px;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 6px 1px rgba(120, 120, 120, 0.3);
    color: $mine-shaft;
    cursor: default;
    text-align: start;
  }

  &__item {
    display: inline-block;
    align-items: center;
    margin: 5px 11px;
    font-size: 12px;
    cursor: pointer;
    word-break: normal;

    // Override necessary since insights-listing-body-box__content *:nth-last-child(1) uses !important
    // with high specificity.
    &.ellipsis-menu__item.ellipsis-menu__item.ellipsis-menu__item {
      margin-bottom: 7px !important;
    }
  }

  &__button {
    display: inline-block;
    align-items: center;
    padding: 4px 0;
    border: none;
    margin: 0 7px 7px;
    font-size: 19px;
    background-color: transparent;
    border-radius: 3px;
    color: $trolley-grey;
    cursor: pointer;
    width: 15px;
  }

  &__click-outside-handler {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: default;
  }
}
