@import 'styles/colors.scss';


.filter {
  &__list-style {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  &__list-items {
    height: 24px;
    margin-right: 25px;
    cursor: pointer;
    line-height: 1.33;
    list-style: none;

    &--active {
      border-bottom: 2px solid $blue;
    }

    &--labelActive {
      display: block;
      cursor: pointer;
    }
  }

}
