@import 'styles/colors.scss';

.innovation-search-bar {
  display: flex;
  position: relative;

  &__field {
    width: 100%;
    position: relative;
    overflow: hidden;

    input {
      width: 100%;
      height: 40px;
      padding: 0 40px 0 10px;
      border: none;
      border-radius: 0;

      font-size: 20px;
      line-height: 30px;
      color: $mine-shaft;

      &::-ms-clear {
        display: none;
      }
    }
  }

  &__search {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.1s ease-out;

    &:hover {
      background: $blue80;
    }

    .icon--search {
      width: 21px;
      height: 21px;

      path {
        fill: white;
      }
    }
  }

  &__cancel-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -40px;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-out;

    &--active {
      right: 0;
    }

    &:hover {
      background-color: $black20;
    }

    .icon--cancel {
      width: 16px;
      height: 16px;

      path {
        fill: $dusty-gray;
      }
    }
  }
}
