@import 'styles/colors.scss';

[data-outline='mouse'] *:focus,
*:focus {
  outline: none;
}

[data-outline='mouse'] *::-moz-focus-inner {
  border: 0;
}

[data-outline='keyboard'] {
  a:focus:not(.Mui-focusVisible):not(.MuiButtonBase-root),
  button:focus:not(.Mui-focusVisible):not(.MuiButtonBase-root) {
    box-shadow: inset 0 0 0 2px $darkBlue;
    outline: none;
  }

  & .pageheader {
    a:focus:not(.Mui-focusVisible) ,
    button:focus:not(.Mui-focusVisible)  {
      box-shadow: 0 0 0 2px $white;
    }
  }

  & .shortcuts-item,
  & .user__popup {
    a:focus,
    button:focus {
      box-shadow: 0 0 0 2px $darkBlue;
    }
  }

  & .navigationitem:focus {
    background-color: $black10;
    box-shadow: none;
  }
}
