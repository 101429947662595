@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.content {
  max-width: 670px;
  word-break: break-word;

  &--with-sidebar {
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 950px;
    }
  }
}

.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 18px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-playerence {
    height: 500px;
  }
}

.cms__article {
  line-height: 1.75;

  .MuiStack-root {
    line-height: 1;
  }

  .hero__image {
    width: 100%;
    margin-bottom: 30px;
  }

  a:not(.content-labels__channel):not(.comment-display__name):not(.MuiLink-root) {
    text-decoration: none;
  }

  figure {
    margin: 0;
  }

  p:empty {
    display: none;
  }

  .body {
    margin-bottom: 30px;

    p {
      margin-bottom: 1rem;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .cms__article--edit {
      & figure {
        background: #fbfbfb;
      }
    }

    figcaption {
      display: block;
      padding: 5px 0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 1.4;
    }

    h2 {
      margin: 32px 0 12px;
    }
  }

  .image-portrait img,
  .image-landscape img {
    max-height: 610px;
  }

  .preamble {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

.article-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  
  .confirm-action-wrapper{
    display: inherit;
  }
  
  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-bottom: 18px;
  }
}
