.faq__question {
  border-bottom: 1px solid #ccc;

  .icon {
    width: 11px;
    height: 5px;
    transition: transform 200ms linear;
  }

  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 13px 10px 20px;
  }

  .h4 {
    margin: 0;
    font-weight: normal;
    line-height: 2;
  }

  .faq__answer {
    overflow: hidden;
    transition: max-height 200ms linear;
  }

  &.open {
    .h4 {
      font-weight: 600;
    }

    .faq__question-text {
      padding-left: 20px;
      margin-bottom: 1rem;
      line-height: 1.75;
    }

    .faq__answer {
      max-height: 1000px;
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  &.closed {
    .faq__question-text {
      margin: 0;
      line-height: 2;
    }

    .faq__answer {
      max-height: 0;
    }

    .faq__question-heading {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}

.faq__toggle-button {
  border: none;
  background: transparent;
  cursor: pointer;
}
