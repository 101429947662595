@import '../SideButtons.scss';

.embed-playerence-button__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.embed-playerence-button__label {
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.embed-playerence-button__container button {
  border: 0;
  background-color: $white;
  height: 50px;
  display: flex;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
}

