@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.body-box {
  padding: 48px 24px;

  @include media-breakpoint-up(md) {
    padding: 60px 24px 60px;
  }

  &--relative {
    position: relative;
  }

  &--blue {
    background: #f3f9fa;
  }

  &--white {
    background: $white;
  }

  &__highligt-body-without-tag-container {
    @include media-breakpoint-up(md) {
      max-width: 1160px;
    }  
  }

  &__highligt-body-wrapper-without-tag {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }  
  }

  &__highligt-box {
    padding: 30px 30px 35px;
    background-color: #f4f9fa;
    word-wrap: break-word;
    margin: 40px 0px 60px 0px;
    height: fit-content;
    

    @include media-breakpoint-up(md) {
      min-width: 320px;
      width: 320px;
      margin: 0px 0px 60px 48px;
    }

      h2 {
        margin-top: 0 !important;
      }

      ::marker {
        font-size: 14px !important;
      }

      li, ul, u, p {
        font-size: 14px !important;
      }

      blockquote {
        padding: 10px 0 10px 20px;
        margin: 0 0 20px;
        border-left: 5px solid #4ca8de;
        background-color: #e2f2ff;
        color: #555;
        font-size: 14px;
        line-height: 1.75;
      }
  }

  &__content_withrelated {
    color: $text-color;
    display: block;
    max-width: 100% !important;

    &__main-text{
      @include media-breakpoint-up(md) {
        max-width: 750px;
      }  
    }

    &__main-text-related {
      display: flex;
      flex-direction: column;
      
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }  
    }

    &__main-text-related-documents {
      display: flex;
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    h2 {
      margin-top: 40px;
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;

      &:nth-child(1) {
        margin-top: 0;
      }

      a, a:hover {
        padding: 0;
        margin: 0;
        color: inherit;
        cursor: default;
        font-size: inherit;
        line-height: inherit;
        overflow-wrap: inherit;
        text-decoration: inherit;
        word-wrap: inherit;
      }

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }

    p {
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    *:nth-last-child(1) {
      margin-bottom: 0 !important;
    }

    .body-box__preamble {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }
  }

  &__content {
    margin: 0 auto;
    color: $text-color;

    h2 {
      margin-top: 40px;
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;

      &:nth-child(1) {
        margin-top: 0;
      }

      a, a:hover {
        padding: 0;
        margin: 0;
        color: inherit;
        cursor: default;
        font-size: inherit;
        line-height: inherit;
        overflow-wrap: inherit;
        text-decoration: inherit;
        word-wrap: inherit;
      }

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }

    p {
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    *:nth-last-child(1) {
      margin-bottom: 0 !important;
    }

    .body-box__preamble {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        line-height: 1.5;
      }
    }
  }

  &__link {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__documents-wrapper {
    display: none;

    &.loaded {
      display: block;
      max-width: 750px;
    }
  }
}
