@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.hamburger {
  z-index: 10;
  display: block;
  width: 36px;
  height: 46px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;

  &:focus {
    outline: none;

    span {
      background: $text-color;

      .hamburger--white & {
        background: $white;
      }
    }
  }

  span {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 2px;
    margin: 0 auto;
    background: $text-color;
    border-radius: 2px;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 14px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 22px;
    }

    &:nth-child(4) {
      top: 30px;
    }
  }

  &--white {
    span,
    &:focus span {
      background: $white;
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  &--open span {
    &:nth-child(1),
    &:nth-child(4) {
      top: 18px;
      width: 0%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}
