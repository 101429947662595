@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.author-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  .title {
    &::before {
      content: none;
    }
  }

  &__author {
    width: 50%;
    padding: 1rem 0.5rem;
    list-style-type: none;
    text-align: center;

    @include media-breakpoint-up(xs) {
      width: 33.33%;
    }

    @include media-breakpoint-up(sm) {
      width: 25%;
    }

    @include media-breakpoint-up(md) {
      width: 20%;
    }
  }

  &__name {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.75rem;
  }

  &__click {
    border: none;
    background: transparent;
    cursor: pointer;
  }
}
