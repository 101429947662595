@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.article-item {
  flex-direction: column;
  border-bottom: 1px solid $gallery;

  @include media-breakpoint-up(sm) {
    display: flex;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: $text-color;
    text-decoration: none;
  }

  &__label-wrapper {
    display: flex;
    color: $job-search-text;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    
  }

  &__label {
    margin-right: 4px;
    color: $job-search-text;    
  }

  &__body {
    padding-bottom: 15px;

    @include media-breakpoint-up(sm) {
      display: flex;
      margin-top: 20px;
    }
  }

  &__image-container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__meta {
    display: flex;
    align-items: center;
    margin-top: 1.25rem;
  }

  &__location {
    color: $blue;
    font-size: 0.75rem;
  }

  &__channel {
    font-size: 0.75rem;
    font-weight: 700;
  }

  &__link {
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      order: 1;
    }
  }

  &__image {
    flex-shrink: 0;
    justify-content: flex-end;

    @include media-breakpoint-up(sm) {
      width: auto;
      max-width: 149px;
      height: 84px;
    }
  }

  &__header {
    word-wrap: break-word;
    margin-bottom: 7px;
  }

  &__header-title {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $near-bondi-blue;
  }

  &__preamble {
    margin: 0;
    word-wrap: break-word;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 7px;
  }

  &__published {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $medium-grey;
  }

  &__inner-wrapper {
    @include media-breakpoint-up(sm) {
      flex-grow: 1;
      margin-right: 20px;
    }
  }

  .content-labels {
    margin-left: -8px;
    margin-right: -8px;

    &__channel {
      margin: 16px 8px 8px 8px !important;
    }
  }

  .text-label {
    margin: 10px 8px 0 8px !important;
  }
}
