@import '../SideButtons.scss';

.embed-expandable-image-button__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.embed-expandable-image-button__label {
  display: inline-block;
  max-width: 57px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
}

.embed-expandable-image-button__icon {
  width: auto;
  height: 46px;
  margin: 0 #{$horizontal-sidebar-button-padding} 0 #{$horizontal-sidebar-button-padding + (46px - 32px)};
}
