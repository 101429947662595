@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.portal-menu-item {
  display: block;
  padding: 18px 2px;
  margin: 0 13px;
  color: $medium-grey;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;

  &--with-arrow {
    padding-right: 20px !important;

    &::before {
      position: absolute;
      top: 23px;
      right: 16px;
      display: block;
      width: 0;
      height: 0;
      border-width: 4px 4px 0 4px;
      border-style: solid;
      border-color: $white transparent transparent transparent;
      content: ' ';
    }
  }

  &--active {
    color: $orient;
    font-weight: bold;

    path {
      fill: $orient;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}

.portal-main-menu__level--second {
  .portal-menu-item {
    padding: 15px 2px;
    color: $white;

    &--mega-menu-opened::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 9px);
      display: block;
      width: 0;
      height: 0;
      border-width: 0 9px 9px 9px;
      border-style: solid;
      border-color: transparent transparent $blue5 transparent;
      content: '';
    }
  }
}

.portal-main-menu {
  &__level--underlined {
    border-top: 1px solid #006495;
    
    .portal-menu-item--mega-menu-opened,
    .portal-menu-item--hovered {
      font-weight: bold;
    }
  }

  &__level--megamenu-opened {
    .portal-menu-item--active {
      border-bottom: 2px solid $white;
    }
  }
}
