@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.share-link__modal-overlay {  
  position: relative;
  margin-left: auto;
}

.share-link__modal-top, .share-link__modal-bottom {  
  position: absolute;
  right: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  color: $text-color;
  z-index: 2;
  background: $white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  white-space: nowrap;

  &::before {
    position: absolute;
    right: 50px;
    bottom: -5px;
    width: 5px;
    height: 0;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.5);
    content: "";
  }
  &::after {
    position: absolute;
    right: 47px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
  }
  > p {
    font-size: 1rem !important;
  }
  > button {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    border: solid 1.5px $semi-black;
    background-color: transparent;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    > svg {
      margin-left: 0.5rem;
      height: 1.5rem;
    }
  }
}


.share-link__modal-top {
  bottom: calc(100% + 1rem);

  &::before {
    height: 0;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.5);  }

  &::after {
    border-top: 10px solid $white;
  }
}

.share-link__modal-bottom {
  top: 45px;
  bottom: auto;

  &::before {
    top: -5px;
    height: 0;
    box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.5);
  }

  &::after {
    top: -10px;
    border-top: none;
    border-bottom: 10px solid $white;
  }
}

.share-link__divider {
  display: flex;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0;

  &::before,
  &::after {
    height: 1px;
    flex: 1 1 auto;
    border-top: 2px solid transparent;
    border-bottom: 1px solid $black75;
    margin: auto;
    content: "";
  }
  &::before {
    margin-right: 17px;
  }
  &::after {
    margin-left: 17px;
  }
}

.share-link__button {
  display: flex;
  align-items: center;
  color: $neutral-700;
  font-size: 0.8125rem;
  padding: 0;

  &:hover {
    color: $accent-600;
    > svg > path {
      fill: $accent-600;
    }
  }
  > span {
    margin-left: 0.375rem;
    white-space: nowrap;
  }
}

// .share-link__share-button {
//   display: flex;
//   align-items: center;
//   padding: 10px 20px 10px 20px;
//   border: solid 1.5px $semi-black;
//   background-color: transparent;
//   border-radius: 6px;
//   font-size: 1rem;
//   cursor: pointer;
//   > svg {
//     margin-left: 0.5rem;
//     height: 1.5rem;
//   }
// }

.share-link__confirmation-message {
  position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    display: flex;
    height: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: transparent;
    transition: background-color 500ms, color 500ms;
    visibility: hidden;

    &--visible {
      bottom: 0;
      height: auto;
      background-color: rgba(255, 255, 255, 0.95);
      color: $text-color;
      visibility: visible;
    }
    &::before {
      padding: 10px;
      text-align: center;
      white-space: normal;
    }
    &--email-option::before {
      content: "An email will now be created in your email client. Thank you for sharing!";
    }
    &--clipboard-option::before {
      content: "Copied to clipboard";
    }
}
