@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.header-title {
  @include media-breakpoint-up(sm) {
    margin-block-end: 15px;
    font-size: 42px;
  }

  margin-block-end: 20px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: $dark-gray;
}

.header-description {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $dark-gray;
  text-align: center;

  &__global-feed {
    text-align: left;
    max-width: 88ch;
  }
}
