
$white: #fff;

$dusty-gray: #9b9b9b;
$dark-gray: #3f3f3f;
$brownish-grey: #666;
$trolley-grey: #808080;
$medium-grey:#666666;

$black: #000;
$black95: #242424;
$black90: #414042;
$black85: #4c4c4c;
$black80: #58595b;
$black70: #6d6e70;
$black75: #707070;
$black60: #808285;
$black50: #939598;
$black40: #a7a9ac;
$black30: #bcbec0;
$black20: #d1d3d4;
$black10: #e6e7e8;
$coal: #333333;

$orient: #005c8a;
$cerulean: #00a0d0;
$light-blue: #b4e6f2;
$near-bondi-blue: #007cab;
$bondi-blue: #008dbe;
$search-blue: #186D86;
$job-search-text: #595959;
$dark-grey: #4a1f1f;
$people-search-background: #F5F5F5;
$avenue-heart-blue: #00A0D0;

$blue: #00a0d0;
$blue90: #19b0d6;
$blue80: #33b8e5;
$blue70: #4cc1df;
$blue60: #66caeb;
$blue50: #93cbe4;
$blue40: #99dbf2;
$blue30: #bdddec;
$blue20: #ccedf8;
$blue10: #e7f1f7;
$blue5: #f3f9fa;
$blue6: #85C3D6;

$darkBlue: #45637a;
$darkBlue90: #3f6d89;
$darkBlue80: #6a8295;
$darkBlue70: #62859e;
$darkBlue60: #8fa1af;
$darkBlue50: #86a0b4;
$darkBlue40: #b5c1ca;
$darkBlue30: #afbfcd;
$darkBlue20: #dae0e4;
$darkBlue10: #dee3e9;
$darkBlueSome: #213D45;

$sustainGreen: #689c41;
$sustainGreen90: #76a34c;
$sustainGreen80: #84aa5d;
$sustainGreen70: #92b270;
$sustainGreen60: #a0ba81;
$sustainGreen50: #aec292;
$sustainGreen40: #bdcda5;
$sustainGreen30: #ccd8b9;
$sustainGreen20: #dce4ce;
$sustainGreen10: #ebeee2;

$green: #a7b8b4;
$green90: #aac2c3;
$green80: #bdc8c2;
$green70: #baccce;
$green60: #ced6cd;
$green50: #cbd8d9;
$green40: #dee3e1;
$green30: #dce5e5;
$green20: #eff1f0;
$green10: #f0f3f3;

$darkGreen: #70927a;
$darkGreen90: #6f938f;
$darkGreen80: #7ca395;
$darkGreen70: #8aa6a3;
$darkGreen60: #98b7ac;
$darkGreen50: #a6bbb8;
$darkGreen40: #b5ccc3;
$darkGreen30: #c4d1d0;
$darkGreen20: #d6e3de;
$darkGreen10: #e6ebea;

$brown: #80686f;
$brown90: #806c75;
$brown80: #a1898d;
$brown70: #97868e;
$brown60: #b9a6a9;
$brown50: #b0a3a9;
$brown40: #d0c4c6;
$brown30: #cbc2c6;
$brown20: #e8e1e2;
$brown10: #e9e5e6;

$orange: #e0684b;
$orange90: #f47f66;
$orange80: #e98971;
$orange70: #f69980;
$orange60: #eea694;
$orange50: #f9b49f;
$orange40: #f4c4b8;
$orange30: #fcd1c1;
$orange20: #f9e1db;
$orange10: #feede6;

$red: #fb3322;
$red90: #a83d2a;
$red80: #b32317;
$red70: #b8624b;
$red60: #c14f32;
$red50: #ca8670;
$red40: #ded5cd;
$red30: #dcb09e;
$red20: #edc9b8;
$red10: #f0dfd6;

$beige: #aa9c8f;
$beige90: #b2a497;
$beige80: #b9ab9b;
$beige70: #c1b5aa;
$beige60: #cbc0b4;
$beige50: #d0c6be;
$beige40: #dcd5cd;
$beige30: #e0d9d3;
$beige20: #eeeae6;
$beige10: #f1eeeb;

$yellow: #f2df74;
$yellow90: #ffec93;
$yellow80: #f3e697;
$yellow70: #ffefaa;
$yellow60: #f6ecb1;
$yellow50: #fff3c1;
$yellow40: #f9f3cb;
$yellow30: #fff7d8;
$yellow20: #fcf9e5;
$yellow10: #fffbef;

$signal-red: #f00;
$signal-green: #00c700;
$signal-orange: #ff7e00;

// Started mapping of colors we actually use, with naming from http://chir.ag/projects/name-that-color
// Color
$iron: #d1d3d4;
$guardsman-red: #c00004;
$gallery: #ececec;
$white-lilac: #f6f7fb;
$sticky: #fce181;
$mine-shaft: #333;
$head-color: #3f3f3f;
$semi-black: #1a1a1a;


// Usage
$border-color: $iron;
$text-color: $mine-shaft;

//Icon colors
$icon-red: #B30B00;
$icon-orange: #C43E1C;
$icon-green: #107C41;
$icon-blue: #185ABD;
$icon-grey: #F2F7F9;
$icon-backup: #ff8b64;

$neutral-0: #FFFFFF;
$neutral-200: #F0F0F0;
$neutral-600: #919191;
$neutral-700: #5C5C5C;
$neutral-1000: #1A1A1A;

$primary-600: #117EB8;

$accent-800: #1E769C;
$accent-600: #00A0D0;
$accent-500: #7CC1DE;

$success-600: #62AD20;
$success-400: #C3DFAA;

$warning-600: #FFAE00;
$warning-300: #FEE6B4;

$critical-600: #FF3535;
$critical-300: #FEB4B4;

$comments-and-likes-active: $accent-600;