@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.navigationitem {
  width: max-content;
  list-style: none;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    width: auto;
  }

  &__link {
    display: block;
    padding: 20px 15px;
    border: 0;
    background: transparent;
    color: $medium-grey;
    font-size: 16px;
    text-decoration: none;
    display: flex;

    @include media-breakpoint-up(md) {
      color: $people-search-background;
      height: 60px;
    }

    &--active {
      font-weight: 700;
      background: #e1f6fa;
      color: $medium-grey;
      
      @include media-breakpoint-up(md) {
        color: $people-search-background;
        background: transparent;
      }
    }

    &[disabled] {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--white & {
    background: $white;
    cursor: pointer;

    &__link {
      color: $orient;
    }
  }
}
