@import 'styles/colors.scss';

.file-action-confirm-modal {
  &__wrapper {
    z-index: 10;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content : center;
  }

  &__container {
    z-index: 11;
    padding: 20px;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 6px 1px rgba(120, 120, 120, 0.3);
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    padding: 20px 0 5px;
    margin-right: -7px;
  }

  &__button {
    display: inline-block;
    padding: 5px 15px;
    border: none;
    margin: 12px 7px;
    background-color: $dark-gray;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background-color: change-color($dark-gray, $alpha: 0.8);
    }

    &--light {
      border: 1px solid $dark-gray;
      background-color: $white;
      color: $dark-gray;

      &:hover {
        background-color: change-color($dark-gray, $alpha: 0.1);
      }
    }
  }

  &__click-outside-handler {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
}
