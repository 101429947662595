@import 'styles/breakpoints.scss';

.onboarding {
  height: 100%;

  @include media-breakpoint-up(sm) {
    min-height: 698px;
  }

  &__container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__text {
    margin: 20px 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1;
    line-height: 1.5rem;
  }

  strong {
    font-family: 'Noto Sans Bold', sans-serif;
    font-weight: normal;
  }

  button {
    touch-action: manipulation;
  }

  &__button-wrapper {
    &--last-item {
      .onboarding__prev-button {
        @media (max-width: 321px) {
          width: 90px;
        }
      }
    }
  }

  &__prev-button,
  &__next-button {
    width: 140px;
  }

  .button--white {
    border-color: #ddd;
  }

  .button--narrow {
    padding: 0.7rem 1rem;
  }

  .modal__footer > div {
    width: 300px;
  }

  &--done {
    .modal__footer > div {
      width: 100%;
      max-width: 366px;
    }
  }
}
