@import 'styles/breakpoints.scss';

.column {
  width: 100%;
  min-width: 0;
  flex: 1 1 auto;
  padding: 0 15px;

  @include media-breakpoint-up(md) {
    flex: 1;

    @for $i from 1 through 4 {
      &--span-#{$i} {
        flex: $i;
      }

      &--count-#{$i} {
        max-width: (100 / $i) * 1%;
      }
    }
  }

  &--no-padding-left {
    padding-left: 0;
  }

  &--no-padding-right {
    padding-right: 0;
  }

  .container--has-spanned-children & {
    max-width: none;
  }

  &--right {
    text-align: right;
  }

  &--no-gutter {
    margin: -15px;
  }

  &--mobile-full-width {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 15px;
    }
  }

  &-inner {
    &--thin {
      max-width: 730px;
    }
  }
}
