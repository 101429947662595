.doc-box {
  max-width: 631px;
  margin: 0 auto;

  .title.align-left {
    text-align: left;
  }
}

.editor-controls {
  position: absolute;
  top: 10px;
  right: 10px;

  a {
    color: white;
    margin-right: 10px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
