@import 'colors';

html {
  @include media-breakpoint-down(sm) {
    &.pageheader__navigation--open {
      position: relative;
      overflow: hidden;
    }
  }
}

html,
body {
  height: 100%;

  @include media-breakpoint-down(sm) {
    .pageheader__navigation--open & {
      position: relative;
      overflow: hidden;
    }
  }
}

body {
  background-color: #f3f9fa;
  color: $text-color;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus:not(:focus-visible) {
  outline: none;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow-x: clip;

  > .body-box {
    flex-grow: 1;
  }

  > .container {
    &:last-child {
      padding-bottom: 30px;
    }
  }
}

.main {
  flex: 1;
  padding: 0 10px;
  background-color: #f3f9fa;

  @include media-breakpoint-up(md) {
    order: 1;
  }

  &__inner {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }
}
