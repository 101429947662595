// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  cl: 0,
  xxs: 320px,
  xs: 414px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1170px,
  tab: 1200px,
  xxl: 1350px,
  xxxl: 1500px,
  xxxxl: 1800px
) !default;

$gradient: linear-gradient(
  to bottom,
  rgba(0, 92, 138, 0.7) 0%,
  rgba(0, 92, 138, 0.7) 50%,
  rgb(0, 92, 138) 100%
);

$pink-gradient: linear-gradient(
  109deg,
  rgba(0, 124, 171, 0.7) 4%,
  rgba(182, 32, 224, 0.7) 127%
);
$green-gradient: linear-gradient(
  109deg,
  rgba(0, 92, 138, 0.7) 4%,
  rgba(68, 215, 182, 0.7) 127%
);
$red-gradient: linear-gradient(
  109deg,
  rgba(0, 76, 114, 0.7) 4%,
  rgba(195, 37, 86, 0.7) 127%
);