@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.sh-house-content {
  width: 100%;  
  color: $white;
  padding: 30px 20px 50px 20px;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: 109px 10px 0 20px;  
  }

  &__container {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__icon-house-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    top: 22px;
    pointer-events: none;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 34px !important;
    font-weight: bold !important;
    line-height: 1.18 !important;
  }

  &__text-content {
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    line-height: 1.42;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }

  &__house-button {
    display: flex;
    justify-content: space-between;  
  }

  &__arrow-left {
    margin-right: 5px;
  }

  &__arrow-right {
    margin-left: 5px;
    transform: rotate(180deg);
  }

  &__button-container {
    width: 100%;
    margin-top: 0;
    height: 101px;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      justify-content: flex-start;
      margin-top: 47px;
      height: 0;
    }
  }

  &__prev-button {
    display: flex;
    align-items: center;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 16px;
    font-weight: bolder;
    margin-right: 40px;
    cursor: pointer;
  }

  &__dummy-icon-house {
    height: 49px;
  }

  &__next-button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40px;
    
    @include media-breakpoint-up(md) {
      align-items: center;
      height: 0px;
    }
    
    text-align: right;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 16px;
    font-weight: bolder;
    cursor: pointer;
  } 

  &__list-item {
    font-size: 20px;
  }

  ul{
    padding-left: 30px;
  }

}