@import 'styles/colors.scss';

.file-manager {
  &__container {
    position: relative;
  }

  &__file-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: flex;
    padding: 5px 15px;
    border: none;
    margin: 12px 7px 0;
    background-color: $dark-gray;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background-color: change-color($dark-gray, $alpha: 0.8);
    }
  }

  &__buttons-row {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 15px 0;
  }
}
