
.select-button {

  display: flex;
  margin: 0px 10px 10px 10px;
  padding: 8px 27px 8px 15px;
  border-radius: 10px;
  border: solid 2px #dedede;
  background-color: #ffffff;
  cursor: pointer;
  color: #616161;

  &__text {
    font-family: 'Noto Sans';
    font-size: 14px;
    font-weight: 600;
  }
  
  &--disabled {
    color: #dedede;
    cursor: default;
  }

  &__check {
    width: 12px;
    height: 12px;
  }
  
  &--selected {

    border: solid 2px #449fcb;
    & > .select-button__check {
        position: relative;
        top: 3px;
        right: 4px;
        background: url('./check-blue.svg') no-repeat;      
    }
    & > .select-button__text {
      color: #449fcb;
    }
  }
}