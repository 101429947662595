@import 'styles/colors.scss';

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 33px;
  height: 33px;
  align-items: center;
  border: 2px solid $white;
  background-color: rgba(63, 63, 63, 0.49);
  border-radius: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);

  &--md {
    width: 40px;
    height: 40px;
  }

  &--lg {
    width: 60px;
    height: 60px;
  }
}

.play-button-symbol {
  position: relative;
  width: 0;
  height: 0;
  margin: 0 auto 0 42%;
  border-width: 8px 0 8px 9px;
  border-style: solid;
  border-color: transparent transparent transparent $white;

  .play-button--lg & {
    margin: 0 auto 0 36%;
    border-width: 12px 0 12px 22px;
  }
}
