@import 'styles/breakpoints.scss';

.app-switch-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  color: #333333;
  font-family: 'Noto Sans', sans-serif;
  padding: 15px 10px;

  svg,
  svg.icon--logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}
