@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.related-articles {  
  flex-direction: column;
  max-width: 360px;
  display: block;

  &--header {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    color: #00A0D0;
    font-weight: 700;
    margin-bottom: 18px;
    margin-top: 18px;
  }
  
  &--link {
    display: flex;
    flex-flow: row-reverse;
    margin-top: 16px;

    > a {
      color: #00A0D0;
    }
  }
  
  @include media-breakpoint-up(md) {
    position: relative;
    top: -18px;
    padding: 0px 0px 0px 18px;
    border-left: 1px solid #D9D9D9;
    height: 456px;
    margin-left: 48px;
    min-width: 360px;
  }
}
