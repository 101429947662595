.expandable-image {
  &__original-image-container {
    position: relative;
    display: flex;
    overflow: visible;
    cursor: zoom-in;

    > img:hover {
      filter: brightness(85%);
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
  }

  &__close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  &__lightbox-background {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.34);
    cursor: default;
    opacity: 1;
    transition: 200ms linear opacity;

    &--fade-out {
      opacity: 0;
    }

    &--lightbox-loading {
      opacity: 0;
      visibility: hidden;
    }

    &--hidden {
      display: none;
      opacity: 0;
    }
  }

  &__lightbox-frame {
    position: relative;
    overflow: auto;
    max-width: 100vw;
    max-height: 100vh;
    background-color: white;
  }

  &__lightbox-image {
    margin: 83px 30px 30px 30px;

    // Increase css selector specificity.
    &.image.image {
      margin-bottom: 30px;
    }
  }
}
