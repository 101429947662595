@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.pageheader {
  background-color: $white;
  width: 100%;

  .new-design-page-spacing  {
    max-width: 1280px;
    margin: 0 auto;
    
    @media (min-width: 512px) {
      padding-left: clamp(1.5rem, calc(1.125rem + .78125vw), 1.75rem);
      padding-right: clamp(1.5rem, calc(1.125rem + .78125vw), 1.75rem);
    }
    @media (min-width: 1440px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Hide tools in new design
  .navigation__tools {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    border-bottom: 0;
  }

  > .container {
    @include media-breakpoint-up(md) {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  .container--mobile-row > .column {
    width: auto;
    flex: none;

    @include media-breakpoint-up(xl) {
      width: 33%;
    }
  }

  .icon {
    &--cancel,
    &--search {
      display: block;
      cursor: pointer;
      fill: $mine-shaft;

      path {
        fill: $neutral-600;
      }
    }
  }

  &__navigation {
    background: #074E74;
    position: relative;
    z-index: 10;
   
    &--blue-background {
      z-index: inherit;

      &::after {
        position: absolute;
        z-index: -1;
        right: 0;
        left: 0;
        height: 405px;
        background-color: #074E74;
        content: '';

        @include media-breakpoint-down(sm) {

          .pageheader__navigation--open & {
            background-color: $white;
          }
        }


        @include media-breakpoint-up(xl) {
          height: 370px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      border-top: 4px solid $white-lilac;
    }

    @include media-breakpoint-up(md) {
      display: block;
    }

    &--open {
      .navigation__inner {
        display: block;
      }
    }
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;

    > .container {
      margin-top: 0 !important;
    }

    @include media-breakpoint-up(md) {
      .search {

        &-bar {
        justify-content: flex-end;
        
        &__field {
          background: #F5F5F5;
          height: 40px;
          border-radius: 50px;

          .icon--cancel {
            width: 18px;
            height: 18px;
            margin: 0px 20px 0 30px;
          }
          
          input {
            font-size: 16px;
            height: 40px;
            padding: 0 20px;
            background: #F5F5F5;

            &:focus {
              padding: 0 18px;
            }
          }
        }

        &__search {
          top: 10px;
        }

        &__suggestion-list {
          color: #5e5e5e;
          width: calc(100% - 20px);
          left: 10px;
          top: 110%;
          z-index: 13
        }
      }
    }

    .icon--search {
      path {
        fill: $neutral-600;
      }

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }

    @include media-breakpoint-up(xl) {
      .search {
        margin: 0 0 0 auto;
      
        &-bar {
          &__field {
            top: 0;
          }
        }
      }
    }
  }

  &__left-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .icon--logo {
    width: 32px;
    height: 28px;

    .cls-1 {
      fill: none;
    }

    .cls-2 {
      fill: $orient;
    }

    .cls-3 {
      fill: #009fcf;
    }

    @include media-breakpoint-up(xl) {
      width: 45px;
      height: 40px;
    }
  }

  .icon--innovation {
    .cls-1 {
      fill: #d2e8f2;
    }

    .cls-2 {
      fill: $cerulean;
    }
  }

  .icon--strategy {
    .cls-1 {
      fill: #00a7d2;
    }
    .cls-2 {
      fill: #45647b;
    }
    .cls-3 {
      fill: #d2e8f2;
    }
    .cls-4 {
      fill: $cerulean;
    }
    .cls-5 {
      fill: #45647a;
    }
    .cls-6 {
      fill: $blue20;
    }
  }

  @include media-breakpoint-down(sm) {
    .pageheader__navigation--open & {
      position: fixed;
      z-index: 20;
      top: 0;
      left: 0;
      overflow: auto;
      width: 100%;
      height: 100%;
    }
  }

  &--mobile {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
