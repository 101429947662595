@import 'styles/colors.scss';

.glossary-box {
  &__header {
    margin-bottom: 8px;
    color: $dark-gray;
    font-size: 18px !important;
  }

  &__abbreviation {
    color: $dark-gray;
    font-size: 18px;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1.67;
    opacity: 0.7;
  }

  &__label {
    margin-bottom: 8px;
  }

  &__item{
    padding: 20px 0;
  }
}

