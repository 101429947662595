@import 'styles/breakpoints.scss';

@include media-breakpoint-up(lg) {
  .article-editor__content-container--portrait-mode {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;

    .article-editor {
      &__hero-content,
      &__text-content {
        flex: 0 0 calc(50% - 12px);
      }
    }
  }
}
