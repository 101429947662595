@import 'styles/colors.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 40px;
  color: #b4e6f2;
  font-size: 0.75rem;

  li {
    margin-right: 6px;
    font-weight: 600;
    line-height: 1.7;
    list-style: none;
    word-break: break-all;

    &::after {
      margin-left: 6px;
      content: '/';
      font-weight: 600;
    }

    a {
      color: #b4e6f2;
    }

    &:last-child {
      &::after {
        content: '';
      }

      a {
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
}
