@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.search-bar {
  display: flex;
  position: relative;

  &__field {
    width: 100%;
    height: 51px;
    position: relative;
    overflow: hidden;
    display: flex;

    input {
      width: 100%;
      padding: 10px 20px 10px 20px;
      border: none;
      border-radius: 50px;
      font-size: 20px;
      color: $mine-shaft;
      border: 1px solid #D9D9D9;

      &:focus {
        border: 3px solid #B4E6F2;
        padding: 8px 18px;
      }

      &::-ms-clear {
        display: none;
      }
    }
  }

  &__search {
    position: absolute;
    top: 15px;
    right: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease-out;

    svg > path {
      fill: $neutral-600;
    }

    @include media-breakpoint-up(md) {
      .icon--search {
        margin: 0;
      }
    }
  }

  &__cancel-btn {
    width: 30px;
    position: absolute;
    right: 50px;
    top: 1px;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-out;
    height: 100%;
    
    p {
      font-size: 14px;
      margin: -2px 0 0;
      color: #007CAB;
    }
  }

  &__cancel-btn--active{
    display: flex;
  }

  &__suggestion {
    padding-bottom: 7.5px;
    padding-top: 7.5px;
    cursor: pointer;

    &--selected {
      background-color: $gallery;
    }
  }

  &__suggestion-list {
    position: absolute;
    top: 100%;
    left: 20px;
    width: calc(100% - 40px);
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    list-style-type: none;
    margin: 0;
    padding: 20px;
    z-index: 1;
  }

  &__suggestion-title {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;

    span.highlight {
      font-family: 'Noto Sans Bold', sans-serif;
    }
  }

  &__suggestion-category {
    color: $brownish-grey;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 20px;
  }

  &__suggestion-preamble{
    cursor: not-allowed;
    font-size: 14px;
    pointer-events: none;
  }

  &__suggestion-title,
  &__suggestion-category {
    margin-bottom: 0;
  }

  &__recentSearch {
    display: flex;
    align-items: center;
    
    &--clock{
      width: 18px;
      height: 18px;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &__recentSearch--text {
    position: relative;
    bottom: 1px;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding-left: 8px;
  }
}
