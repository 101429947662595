@import 'styles/colors.scss';

.portal-mobile-menu {
  overflow: hidden;
  padding: 0 15px;
  margin: 0;

  ul {
    padding: 0;
  }

  > li:nth-child(1) {
    border-bottom: 1px solid #cbcbcb;
  }

  .navigation__items {
    margin: 0 -15px;
  }

  &__list {
    padding: 0;
    margin: 0;

    &--children {
      + .tree-item {
        > span {
          box-shadow: 0 0 5px 2px rgba(63, 63, 63, 0.32);
        }

         &__portal {
          > span {
            box-shadow: 0 0 5px 2px rgba(63, 63, 63, 0.32);
          }
        }
      }
    }
  }

  .tree-item {
    margin: 0 -15px;

    a {
      padding: 15px;
      line-height: inherit;
    }

    &--depth-1 {
      + .tree-item--depth-1 {
        .tree-item__container {
          border-top: none;
        }
      }

      a {
        background-color: $white-lilac;
      }
    }

    &--active {
      a {
        background: #e1f6fa;
        color: $orient;
      }
    }
  }
}
