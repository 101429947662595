.comments {
  max-width: 670px;

  > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .share-link {
    margin-right: 0;

    &__button {
      margin-left: 0;
    }
  }
}
.story-comments {
  max-width: 900px;
}