@import 'styles/breakpoints.scss';

.story {
  
  &__header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 40px;
    font-weight: normal;
    a {
      margin-left: auto;
    }
  }

  &__hero-image-gradient {
    background: linear-gradient(#ffffff00 0%, 75%, #000000d3);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  
  &__hero-image {
    position: relative;
    max-width: 1110px;   
    width: 100%;
    display: flex; 
    @media only screen and (min-width: 1110px) {
      left: 50%;
      transform: translate(-50%)
    }
  }

  &__hero-video {
    @media only screen and (min-width: 1110px) {
      max-width: 1110px;
      position: relative;
      margin: auto;
    }
  }

  &__comments-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__comments {
    width: 100%;
    margin: 0 20px 0 20px;
    @include media-breakpoint-up(md) {
      width: 70%;
      margin: 0;
    }
  }

  &__same-topic-container {
    margin-top: 60px;
  }
}