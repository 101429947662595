@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.button {
  display: inline-block;
  flex-shrink: 0;
  padding-top: 0.7rem;
  padding-right: 4.15rem;
  padding-bottom: 0.7rem;
  padding-left: 4.15rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
    font-weight: 400;
  }

  &--black {
    background-color: $text-color;
    color: $white;
  }

  &--blue {
    background-color: $blue;
    color: $white;
  }

  &--orient {
    background-color: $orient;
    color: $white;
  }

  &--white {
    border-color: $text-color;
    background-color: $white;
    color: $text-color;

    &.button--selected {
      background-color: $text-color;
      color: $white;
    }
  }

  &--transparent {
    border-color: $text-color;
    background: transparent;
    color: $text-color;
  }

  &--transparent-white {
    border-color:$white;
    background: transparent;
    color: $white;
  }

  &--transparent-orient {
    background: transparent;
    color: $orient;
  }

  &--none {
    border: none;
    background: transparent;
  }

  &--ghost {
    border: 1px solid $white;
    background: none;
  }

  &--small {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-size: 0.9rem;
  }

  &--large {
    font-size: 1.125rem;
  }

  &--narrow {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
  }

  &--block {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    opacity: 0.6;
  }
  
  &--align-right {
    @include media-breakpoint-up(md) {
      margin-left: auto;
    }
  }

  &--search {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
}
