@import 'styles/colors.scss';
@import 'styles/helpers.scss';
@import 'styles/breakpoints.scss';

.language-select {
  @include custom-scrollbar($gallery, $white);

  position: relative;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__inner {
    padding: 0 10px;

    .language-select--open & {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__label {
    position: relative;
    display: flex;
    overflow: hidden;
    width: auto;
    height: 42px;
    align-items: center;
    padding-right: 3rem;
    border-width: 2px;
    margin-bottom: 0;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
    padding-left: 0.75rem;
    font-size: 0.8rem;

    .icon {
      width: 18px;
      height: 18px;
      transition: transform 200ms ease;

      &--white, &--white svg {
         color: $white;
      }
      &--black, &--black svg  {
        color: #333;
     }
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: 8px;
    right: -2px;
    bottom: 0;
    width: 20px;
    padding: 0 5px;
    padding-right: 35px;
  }

  &__options {
    position: absolute;
    z-index: 3;
    right: 0;
    left: auto;
    overflow: auto;
    width: 200px;
    padding: 5px 16px;
    margin-top: 6px;
    background: $white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);

    &--original {
      @include media-breakpoint-up(md) {
        left: auto;
      }
    }
  }

  &__header {
    margin: 5px 0 10px 0;
    color: #3f3f3f;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01px;
    line-height: 1.5;
    text-align: left;
  }

  &__list {
    max-height: 500px;
    padding-left: 0;
    margin: 2px 0;
    line-height: 1;
    list-style-type: none;
  }
}  
