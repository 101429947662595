@import 'styles/breakpoints.scss';

//will be removed after old logo component deprecation
.app-switch-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.app-switch__polygon {
  position: absolute;
  font-size: 0;
  bottom: -0.25rem;
  left: calc(50% - 5px);
  
  g > path {
    fill: #074E74;
  }
}

.app-switch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0%;
  margin-left: 0%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: center;
  }

  li:last-child > a > span {
    border: none;
  }

  &__list {
    position: absolute;
    z-index: 30;
    top: 13px;
    left: 0%;
    display: none;
    width: 294px;
    padding: 0;
    margin: 0;
    background: #ffffff;
    list-style-type: none;
    transform: translateX(-50%);

    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 50px;
      width: auto;
      min-width: 220px;
    }

    &--opened {
      display: block;
      box-shadow: 0 2px 5px rgba(63, 63, 63, 0.32);
    }

    .app-switch-item__text {
      margin-right: 0;
    }
  }
}
