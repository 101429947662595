@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.news-article {
  &__container--portrait {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .news-article__article {
      width: 50.5%;
    }

    .image {
      width: 39%;
      height: auto;
      margin-bottom: 0;
    }
  }

  &__article {
    .image {
      width: auto;
    }

    &__good-to-know {
      word-wrap: break-word;
      background-color: #f4f4f4;
      padding: 30px;

      &--title {
        font-size: 16px !important;
        font-weight: 600 !important;
        font-family: 'Noto Sans' !important;
      }

      ::marker {
        font-size: 14px;
      }

      u {
        font-size: 14px;
      }

      blockquote {
        padding: 10px 0 10px 20px;
        margin: 0 0 20px;
        border-left: 5px solid #4ca8de;
        background-color: #e2f2ff;
        color: #555;
        font-size: 14px;
        line-height: 1.75;
      }
    }
    
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;

      &--content {
        width: 100%;
        margin-right: 20px;
      }

      &__good-to-know {
        margin-right: 10px;
        min-width: 325px;
        max-width: 325px;
        height: fit-content;
      }
    }
  }

  &__labels-container--portrait {
    width: 50.5%;
  }

  &__hero-image-wrapper--portrait {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    .image {
      position: absolute;
      bottom: -5%;
      left: 0;
      width: 100%;
    }

    &::after {
      display: block;
      padding-top: 100%;
      content: '';
    }
  }

  &__same-topic-container {
    padding: 0 15px;
  }
}
