@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.box {
  padding: 0.9375rem;
  box-shadow: 0 2px 8px rgba(9, 88, 112, 0.08);

  @include media-breakpoint-up(md) {
    padding: 1.875rem;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--not-shadow{
    padding: 0.2rem 1.875rem;
    box-shadow: none;
  }

  &--transparent {
    background-color: transparent;
  }

  &--white {
    background-color: $white;
  }

  &--green {
    background-color: #e6faea;
  }

  &--blue {
    background-color: #e1f6fa;
  }

  &__preamble {
    margin: 5px 0 20px;
    font-size: 0.75rem;
  }

  &--wide {
    width: 110%;
    margin-left: -5%;
    
    @include media-breakpoint-up(xl) {
      padding: 0 0 30px 0;
      margin-top: 90px;
    }
  }
}
