@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.filter-box {

  &__fields-holder {
    display: flex;
    flex-wrap: wrap;
    color: #3f3f3f;
    text-align: left;
  }

  &__reset-panel {
    display: flex;
    justify-content: space-between;
    margin: 0 0 22px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__reset-title {
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    line-height: 24px;
  }

  &__reset-button {
    font-size: 12px;
    line-height: 24px;
    color: $white;
    background: rgba(3, 51, 51, .6);
    padding: 0 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    pointer-events: none;
    transition: background .3s ease;

    .insights-database--filtered & {
      background: $mine-shaft;
      pointer-events: all;
    }
  }

  &__field {
    width: 33.33%;
    padding: 0 0 0 15px;
    margin: 0 0 16px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 !important;
    }

    &:nth-child(3n-1) {
      padding: 0 15px;
    }

    &:nth-child(3n-2) {
      padding: 0 15px 0 0;
    }

    &:last-child {
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    & > p {
      font-size: 12px;
      color: $white;
      margin: 0 0 4px;
    }
  }

  .select {

    &--checked {

      .select__inner, .select__label {
        background-color: $mine-shaft;
      }

      .select__label {
        color: $white;
      }

      .select__label span {
        background-color: $mine-shaft;
      }

      .icon {
        fill: $white;
      }
    }

    &__inner {
      border: none;
    }
  }
}