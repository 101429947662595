@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.article-tags {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &__list {
    padding: 25px 0;
  }

  &__item {
    margin: 0 0 20px;
  }

  &__item-label {
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    color: $dusty-gray;
    margin: 0 0 5px;
  }

  &__item-value {
    font-weight: 600;
    font-size: 14px;
    color: $mine-shaft;
  }

  &__item-link {
    color: $mine-shaft !important;
    text-decoration: none;

    &::after {
      content: ', ';
    }

    &:last-child:after {
      content: '';
    }
  }
}