@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.person-item {
  width: 100%;
  padding-top: 10px;

  &__container {
    padding: 10px 0 10px 10px;
    background-color: $people-search-background;
    display: flex;
    justify-content: space-between;
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $semi-black;
    margin-bottom: 6px;
  }

  &__title,
  &__division {
    color: $dark-grey;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 18px;;
    word-break: break-all;
  }

  &__email {
    display: block;
    text-decoration: none;
    color: $medium-grey;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 5px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  &__contact-teams {
    margin: 0;
  }

  &__teams-link {
    padding-right: 10px;
  }

  &__link {
    text-decoration: none;
  }
  &__phone {
    text-decoration: none;
    color: $medium-grey;
   
    &:focus,
    &:hover {
      outline: none;
      text-decoration: underline;
    }
  }

  &__link {
    display: flex;

    &:hover,
    &:active {
      text-decoration: none;
    }

    .user-image {
      margin-right: 18px;
    }
  }

  &__contact-wrapper {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    border-bottom: 1px solid $gallery;
    padding: 5px 0;
    
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      word-break: break-all;
    }
  }

  &__name,
  &__title,
  &__email,
  &__division,
  &__phone {
    display: block;
  }

  &__user-image {
    display: flex;
  }
}

a {
  &.person-item__email {
    word-break: break-all;
  }
}
