@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.job-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-bottom: 1px solid $gallery;
  font-family: 'Noto Sans', sans-serif;
  margin-top: -16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__body {
    margin-top: 20px; 
  }

  &__jobs {
    font-size: 14px;
    margin: 0;
  }

  &__header {
    width: 90%;
    margin-bottom: 4px;
    font-size: 16px !important;
  }

  &__meta-label-container {
    display: flex;
    justify-content: space-between;
  }

  &__meta {
    margin: 0;
    margin-right: 60px;
    color: $brownish-grey;
    font-size: 0.875rem;
    letter-spacing: 0.01px;
    word-wrap: break-word;
    
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;
  }

  &__link {
    flex-shrink: 0;
    color: $near-bondi-blue;
    font-weight: 400;

    @include media-breakpoint-up(sm) {
      order: 1;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__info {
    padding-right: 10px;
    border-right: 1px solid $mine-shaft;
    margin-right: 10px;
    color: $job-search-text;
  }
  
  &__department {
    color: $job-search-text;
  }

  &__icon {
    position: absolute;
    top: 20px;
    right: 1px;
    width: 60px;
    height: 60px;
    padding: 12px;
    background-color: $trolley-grey;
    border-radius: 50%;
  }
}

.doc-icon {
  fill: white;
}
