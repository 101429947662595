
.no-user {
  &__container {
    position: relative;
    margin-top: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    overflow: auto;
    width: 90%;
    padding: 30px;
    border: 2px solid #f59f08;
    border-left: 14px solid #f59f08;
    max-height: 90%;
    background-color: white;
  }

  &__image-wrapper {
    min-width: 50px;
  }

  &__text-wrapper {
    margin-left: 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;
    color: #f59f08;
    margin-top: 10px;
  }

  &__text {
    line-height: 1.88;
    color: #4c4c4c;
  }

  &__mail-link {
    color: #00a0d0;
    text-decoration: none;
    font-weight: bold;
  }
}