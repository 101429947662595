@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.comment-content {
  position: relative;
  display: flex;
  font-size: 0.75rem;

  &__content {
    margin-left: 1rem;
  }

  &__content-top {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  &__content-bottom {
    display: flex;
    align-items: center;
    line-height: 1.5;
  }

  &__divider {
    border-right: 1px solid #EBEBEB;
    margin: 0 0.5rem;
    height: 1rem;
  }

  &__name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1A1A1A;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__title {
    color: #5C5C5C;
  }

  &__date {
    color: #919191;
  }

  &__text {
    color: #1A1A1A;
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
    margin-bottom: 1rem;

    a {
      text-decoration: underline !important;
    }
  }

  &__reply-button {
    border: none;
    background-color: unset;
    text-decoration: underline;
    font-weight: 600;
    font-size: 0.813rem;
    color: #5C5C5C;
    cursor: pointer;
    padding: 0;

    &-show {
      color: #00A0D0;
    }
  }
}
