@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';
@import 'styles/colors.scss';

.insights-listing-header-box {
  position: relative;
  padding: 36px 24px 75px;
  background-color: $orient;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: white;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 60px 30px;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &__background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__background-inner {
    position: relative;

    &--full-height {
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__background-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $gradient;
  }

  &__content {
    position: relative;
    max-width: 670px;
    margin: 0 auto 40px auto;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 950px;
      padding-right: 280px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-family: 'Noto Sans Light', sans-serif;
    font-size: 28px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
  }

  &__italic-text {
    font-style: italic;

    @include media-breakpoint-up(md) {
      margin-top: -20px;
    }
  }

  &__menu {
    position: absolute;
    top: 10px;
    right: 10px;

    @include media-breakpoint-down(md) {
      top: 15px;
      right: 20px;
    }

    &-link {
      color: $white;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
