body {
  @include media-breakpoint-down(sm) {
    .container {
      transition: margin-top 0.2s ease-in-out 0.2s;
    }

    &.tools-open .container,
    &.search-open .container {
      margin-top: 56px;
    }
  }
}
