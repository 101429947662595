@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.folder-view {
  &__path {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  &__path-segment {
    padding: 0 0 12px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__path-separator {
    padding: 0 0 12px 5px;
    margin: auto 3px auto 0;
    cursor: default;

    &:first-child  {
      display: none;
    }

    &--base {
      &, &:first-child {
        display: block;
      }
    }
  }

  &__message {
    width: 100%;
    padding: 7px 0 7px 23px;
    margin: auto 15px;
    text-align: center;
  }

  &__sorting-control {
    &.sorting-control {
      display: flex;
      margin-left: auto;
      color: $black;
    }

    .sorting-control {
      &__order svg {
        filter: invert(100%);
      }

      &__label, &__type {
        font-size: 14px;
      }
    }
  }
}
