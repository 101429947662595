@import 'styles/colors.scss';

.mini-snackbar-item {
  &__message {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px 38px 20px 38px;
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
    cursor: pointer;
    line-height: 1.5;
    opacity: 0;
    transition: opacity 250ms ease-out, transform 250ms ease-out;
    user-select: none;

    &--entered {
      opacity: 1;
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    }

    &--error {
      background-color: #f00;
      color: $white;
    }

    &--success {
      background-color: #00c700;
      color: $white;
    }
  }

  &__close-button {
    position: absolute;
    top: 26px;
    right: 26px;
    width: 16px;
    height: 16px;

    &--error,
    &--success {
      fill: $white;
    }
  }
}
