@import 'styles/colors.scss';

.comment-like-button {
  display: flex;
  min-height: 20px;
  align-items: center;
  padding: 0;
  color: $dusty-gray;
  fill: none;
  stroke: #A6A6A6;
  transition: opacity 0.7s ease;

  &:hover, &:hover svg > path  {
    color: $comments-and-likes-active;
    fill: $comments-and-likes-active;
    stroke: $comments-and-likes-active;
    opacity: 1;
  }

  &__text {
    color: #5C5C5C;
    margin-left: 0.375rem;
    font-size: 0.8125rem;
  }

  &--blue {
    color: $comments-and-likes-active;
    fill: $white;
    stroke: $comments-and-likes-active;

    &:hover {
      fill: $comments-and-likes-active;
      stroke: $comments-and-likes-active;
    }
  }

  &--liked-by-me {
    color: $comments-and-likes-active;
    fill: $comments-and-likes-active;
    stroke: $comments-and-likes-active;
    opacity: 1;
  }

  &--liked-by-others {
    stroke: $comments-and-likes-active;
    opacity: 1;
  }

  &--commented-by-others {
    svg > path {
      stroke: $comments-and-likes-active;
    }
  }
}
