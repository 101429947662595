.good-to-know-title {
  &--editable {
    position: relative;

    h2 {
     font-size: 1.25rem;
     line-height: 1.5;
     font-family: 'Noto Sans';
   }
 
   .info-box {
     position: absolute;
     top: 11px;
     right: 11px;
     margin: 0;
     background: white;
     z-index: 2;
 
     svg {
       height: 18px;
       width: 18px;
     }
   }
  }

  &--non-editable {
    display: flex;
    justify-content: space-between;
  
    .title {
      padding-bottom: 0.5rem;
    }
  }
}
