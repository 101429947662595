@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.user-menu {
  position: relative;
  display: none;
  flex-direction: row;
  align-items: right;
  margin-right: 3px;
  color: $white;
  font-size: 15px;

  @include media-breakpoint-up(md) {
    display: flex;
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 0;
  }

  &__button {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: right;
  }

  &__user {
    display: flex;
    align-items: center;
    background: $white;
    cursor: pointer;
    text-align: left;
  }

  &__details {
    &__wrapper {
      margin: 20px 20px 0; 
      color: black;
      background-color: $white-lilac;
    }

    &__container {
      padding: 8px;

      p {
        margin: 0;
      }
    }


    &--name {
      font-family: 'Noto Sans', sans-serif;
      font-weight: 700;
      font-size: 16px;
    }

    &--location {
      font-size: 12px;
    }
  }

  &__name,
  &__location {
    display: block;
  }

  &__location {
    color: $dusty-gray;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &__name {
    color: $text-color;
    font-size: 0.875rem;
    line-height: 1.2rem;

    @include media-breakpoint-up(xl) {
      overflow: hidden;
      max-width: 20vw;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include media-breakpoint-up(lg) {
      max-width: 20vw;
    }
  }

  &__popup {
    position: absolute;
    z-index: 13;
    top: 47px;
    left: -200px;
    display: flex;
    width: 246px;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.3);
    border: 1px solid #E6E6E6;
    border-radius: 4px;
  }

  &__items {
    padding: 0;
    margin: 0;
  }

  &__item {
    list-style-type: none;
  }

  &__link {
    display: block;
    padding: 20px;
    color: $orient;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
  }

  &__divider {
    margin: 0 26px;
  }

  &-image {
    vertical-align: middle;
  }

  &__info-box {
    position: absolute;
    display: inline-block;
    top: -13px;
    right: 45px;
    background-color: #333333;
    padding: 8px;
    border-radius: 4px;
    z-index: 100;
    box-shadow: 0 2px 8px rgba(9, 88, 112, 0.08);
    white-space: nowrap;
    
    &--name {
      font-family: 'Noto Sans', sans-serif;
      display: block;
      margin: 0;
    }

    &--location {
      font-size: 12px; 
      margin: 0;
    }
  }
}
