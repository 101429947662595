@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.gradient-block {
  position: relative;
  height: 475px;
  background: $orient;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &__inner-content-holder {

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__inner-content-box {
    max-width: 1158px;
    text-align: left;
    padding: 30px 25px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      padding: 60px 24px;
    }
  }

  &__inner-content {
    color: $white;

    @include media-breakpoint-up(md) {
      position: relative;
      z-index: 1;
      width: 52%;
      margin: 0 auto 0 0;
    }
  }

  &__title {
    display: block;
    font-size: 20px;
    line-height: 28px;
    font-family: 'Noto Sans Light', sans-serif;
    margin: 0 0 30px;

    a, a:hover {
      padding: 0;
      margin: 0;
      color: inherit;
      cursor: default;
      font-size: inherit;
      line-height: inherit;
      overflow-wrap: inherit;
      text-decoration: inherit;
      word-wrap: inherit;
    }
    
    @include media-breakpoint-up(md) {
      font-size: 28px;
      line-height: 35px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 28px;
    font-family: 'Noto Sans', sans-serif;
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    font-family: 'Noto Sans', sans-serif;
    color: $white;
    text-decoration: none;
    padding: 12px 35px;
    border: 1px solid $white;
    border-radius: 4px;
    margin-top: 1rem;
  }

  &__inner-image {
    display: none;
    position: relative;
    flex-grow: 1;
    padding-top: 56.25%;
    background-color: lightgrey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      display: block;
      width: 50%;
      min-height: 475px;
      padding-top: 0;
      margin: 0 0 0 auto;
    }

    &-gradient {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, rgba(0,92,138,1) 0%,rgba(0,92,138,0) 100%);
    }
  }
}