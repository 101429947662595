@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.add-news-btn {
  display: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-left: 30px;
    color: #b4e6f2;
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-decoration: none;
  }
}

.add-links {
  margin: 20px 15px 0 auto;
  display: flex;

  a {
    text-decoration: none;
  }
  
  &--add {
    color: $white;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 31px;
    height: 24px;
    margin: 7px 10px 0 0;
  }

  &--wrapper {
    display: flex;
    overflow: hidden;
    border-radius: 4px;

    a:nth-last-child(1) > div {
      border: none;
    }
  }
  
  &--buttons {
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background: #F1F7F9;
    color: #1E769C;
    padding: 8px 16px;
    border-right: #1E769C 1px solid;
  }
}

.content-dropdown {
  display: flex;
  width: 95%;
  height: 44px;
  padding: 8px 30px;
  border: solid 2px #1a1a1a;
  margin: 0 auto 10px auto;
  appearance: none;
  background: transparent;
  background-image: url("../../components/Icon/Arrow.svg");
  background-position-x: calc(100% - 30px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  border-radius: 6px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  line-height: 1.33;
}

.filter-text {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-gray);
  padding: 0;
}

.filter-button {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #cfcfcf;
  background: none;
  user-select: none;

  &--active {
    color: var(--dark-grey);
    cursor: pointer;
  }
}
