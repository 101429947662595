@import 'styles/breakpoints.scss';

.language-select-feed-container { 
  @include media-breakpoint-down(md) {
    margin-bottom: 2.5rem;

    .language-select {
      &-flex-container {
        display: block;
      }
      
      &__inner {
        padding: 0;
      }
      
      &__label {
        width: 95%;
        margin: 0 auto;
        padding: 8px 30px;
        font-size: 18px;
        font-weight: normal;
      }
      
      &__icon-wrapper {
        padding-right: 51px;
      }
    }
  }
}
