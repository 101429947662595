@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.document-item {
  padding-top: 0.45rem;
  border-bottom: 1px solid #ececec;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    margin: 0;
  }

  &__header-text {
    color: #007CAB;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: 'Noto Sans', sans-serif;
    word-break: break-all;
  }

  &__content {
    margin: 0;
  }

  &__location-share-wrapper {
    display: flex;
    justify-content: space-between;
    
    @include media-breakpoint-up(md) {
      margin-left: 92px;
    }
  }

  &__location-link {
    text-decoration: none;
  }

  &__location-text {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $orient;
  }

  &__share-download-wrapper {
    display: flex;
  }

  &__share {
    margin-right: 25px;
  }

  &__link {
    color: $dark-gray;
    text-decoration: none;
  }

  &__content-wrapper {
    @include media-breakpoint-up(md) {
      padding-left: 20px;
    }
  }

  &__content-link {
    text-decoration: none;
  }

  &__content-link-text{
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $medium-grey;
  }

  &__image {
    max-width: 49px;
  }

  &__body {
    margin-bottom: 20px;
  }

  &__inner {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
}
