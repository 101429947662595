@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.link-item {
  display: inline-block;
  margin: 0.25rem 0;
  list-style-type: none;

  + .link-item {
    border-left: 1px solid $gallery;
  }
  
  @include media-breakpoint-up(xl) {
    + .link-item.public-page {
      border-left: 1px solid $gallery;
    }

    + .link-item {
      border: none;
    }

    &.public-page {
      display: inline-block;
      font-weight: 700;
      font-size: 0.75rem;

      .link-item__link {
        margin: 0 5px;
      }
    }

    display: block;
    margin: 0.75rem 0;
    font-size: larger;
    font-weight: 300;
  }

  &--no-border-mobile {
    border-left: none;

    @include media-breakpoint-up(sm) {
      border-left: 1px solid $gallery;
    }
  }

  &__link {
    margin: 0 5px;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }
}
