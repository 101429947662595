
@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.new-sorting-control {
  display: flex;
  margin-right: 30px;
  
  &__type {
    text-decoration: underline;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
  }

  &__order {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 3px;
    position: relative;

    &:hover {
      background-color: $black20;
    }

    &:active {
      background-color: $black30;
    }

    &--asc {
      transform: scaleY(-1);
    }
    svg {
      filter: invert(42%) sepia(9%) saturate(14%) hue-rotate(10deg) brightness(94%) contrast(98%);
    } 
  }
  &__options {
    position: absolute;
    top: 5px;
    min-width: 100px;
    right: 30px;
    z-index: 1;
    padding: 5px 0;
    margin: 0;
    list-style-type: none;
    background-color: $white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    color: $mine-shaft;

    li {
      padding: 5px 5px;
      cursor: pointer;
      font-size: 14px;

      &:hover,
      &.active {
        background-color: $gallery;
      }
    }
  }
}
