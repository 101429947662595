@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.events-list-block-box {
  display: flex;
  align-items: center;
  padding: 30px 0;
  background-color: $white-lilac;

  &__title-link-wrapper{
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 590px;
    }

    @media screen and (min-width: 1208px) {
      width: 1180px;
    }
    
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.67;
    color: #242424;
    font-family: 'Noto Sans', sans-serif;

    @media screen and (max-width: 1208px) {
    margin-left: 18px;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
    @media screen and (max-width: 1208px) {
      flex-direction: column;
      align-items: center;
      flex-wrap: no-wrap;
    }
  }

  &__link-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 10px 0 0;

    @media screen and (max-width: 1208px) {
      width: 100%;
      padding-right: 10px;
    }
  }

  &__link-button {
    position: relative;
    right: 8px;
    display: flex;
    font-size: 18px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.33;
  }

  &__arrow-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 1px;
    width: 18px;
    height: 22px;
    transform: rotate(270deg);
  }
}