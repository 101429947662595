@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.events-list-link {
  margin: 0 15px 30px 15px;
  width: 335px;
  
  &__container {
    text-decoration: none;
  }

  &__wrapper {
    height: 170px;
    padding: 15px 15px 10px;
    display: flex;
    background-color: $white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);

    &:hover {
      cursor: pointer;
      box-shadow: 12px 11px 23px 5px rgba(115,115,115,0.15);
      transition: 0.3s;
    }
  }

  &__wrapper--noevents {
    height: 170px;
    padding: 15px 0px 10px 15px;
    display: flex;
    background-color: $white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
  }
   
  @include media-breakpoint-up(md) {
    width: 560px;
    padding: 15px 20px 10px 15px;
  }

  &__date {
    margin-right: 15px;
  }

  &__day {
    font-weight: 600;
    line-height: 1.67;
    text-align: center;
    color: #005c8a;
    font-size: 18px;
    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  &__month {
    color: #005c8a;
    font-size: 12px;
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }

  &__divider {
    width: 2px;
    height: 50px;
    background-color: #e6e6e6;
    padding-right: 2px;
    margin-right: 13px;
  }

  &__text-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__text-wrapper--noevents {
    width: 100%;
    height: 100%;
    position: relative;
    top: 30%;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 1;
    
    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.67;
      font-weight: 600;
    }
  }

  &__time-site {
    color: #7e7e7e;
    font-size: 12px;
    line-height: 1.17;
    margin-top: 4px;
 
    @include media-breakpoint-up(md) {
      font-size: 12px;
      line-height: 1.17;
      margin-top: 0px;
    }
  }

  &__description {
    color: #333333;
    font-size: 12px;
    line-height: 1.17;
    margin: 8px 0px 15px 0;
    width: 240px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 1.43;
      margin: 8px 0 10px 0;
      width: 100%;
    }
  }

  &__read-more {
    color: #00a0d0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    margin-right: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 1.43;
      margin: 0;
    }
  } 
}
