@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.blocked-user {
  width: 100%;
  height: 100%;
  background-color: $cerulean;

  &--content {
    max-width: 720px;
    min-height: 300px;
    margin: 60px 20px;
    border-radius: 4px;
    box-shadow: 0 3px 50px rgba(0, 0, 0, 0.16);

    @include media-breakpoint-up(sm) {
      margin: 60px auto;
    }
  }

  &__logo {
    margin-top: 1rem;
    
    &__link {
      display: flex;
      align-items: center;
      text-decoration: none;

      .icon--logo {
        margin-right: 1rem;
      }
    }
  }
}
