.image {
  &--animate {
    transition: opacity 200ms ease-in;

    &.image--is-loading {
      opacity: 0;
    }

    &.image--loaded {
      opacity: 1;
    }
  }
}

.story-hero {
  position: relative;
  z-index: 2;
  
  @media only screen and (min-width: 1068px) {
    min-width: 1110px;

  }

}

.some-hero {
  border-radius: 6px;
}

.story-card-item__image {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}