@import 'styles/colors.scss';

.innovation-search-bar {
  &__suggestion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 14px;
    cursor: pointer;

    &--selected {
      background-color: $gallery;
    }
    
  }

  &__suggestion-title {
    color: #5e5e5e;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    line-height: 22px;

    span.highlight {
      font-family: 'Noto Sans Bold', sans-serif;
    }
  }

  &__suggestion-category {
    color: $brownish-grey;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 20px;
  }

  &__suggestion-preamble{
    cursor: not-allowed;
    font-size: 14px;
    pointer-events: none;
  }

  &__suggestion-title,
  &__suggestion-category {
    margin-bottom: 0;
  }
}