@import 'styles/colors.scss';

.crossword-input {
  overflow-x: scroll;

  &__text {
    width: 40px;
    height: 40px;
    margin-right: -2px;
    margin-bottom: -2px;
    border: solid 2px $mine-shaft;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &--center {
      background: #fce181;
    }

    &--focused {
      background: #b4e6f2;
    }
  }

  &__hidden {
    width: 38px;
    height: 38px;
  }

  &__rownum {
    width: 38px;
    padding-top: 2px;
    padding-right: 8px;
    font-size: 16px;
    line-height: 36px;
    text-align: right;
  }
}
