.comment-actions {

  &__menu-button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    > svg {
      display: inline-block;
      vertical-align: middle;
    }
  }
}