@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.insights-database-body-box {

  @include media-breakpoint-down(sm) {
    padding: 30px 25px;
  }

  @include media-breakpoint-up(md) {
    padding: 60px 15px;
  }

  @include media-breakpoint-up(lg) {
    padding: 60px 25px;
  }

  &--relative {
    position: relative;
  }

  &--blue {
    background: #f3f9fa;
  }

  &--white {
    background: $white;
  }

  &__news-box {
    max-width: 1110px;
    text-align: center;
    margin: 0 auto;
  }

  &__news-top-panel {
    display: flex;
    justify-content: space-between;
    color: #3f3f3f;
    margin: 45px 0 45px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
    }
  }

  &__news-title {
    font-family: 'Noto Sans', sans-serif;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(sm) {
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;
      line-height: 35px;
      color: $mine-shaft;
    }
  }

  &__news-all {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: $blue;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__news-all-mobile {
    display: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      display: inline;
    }
  }

  &__news-count {
    display: flex;
    justify-content:space-between;
    align-items: baseline;
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(sm) {
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;
      line-height: 35px;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
    }
    
  }
  &__result-wrapper {
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }
  }

  &__divider {
    @include media-breakpoint-down(xs) {
      width: 100%;
      border-bottom: 1px solid #ececec;
    }
  }

  &__sorting-wrapper {
    @include media-breakpoint-down(xs) {
      align-self: flex-end;
      margin-bottom: 20px;
    }
  }

  &__news-list {
    list-style: none;
    text-align: left;
    padding: 0 0 12px;
    margin: 0;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  &__news-item {
    margin: 0 0 44px;

    @include media-breakpoint-down(sm) {
      padding: 0 0 30px;
      border-bottom: 1px solid $gallery;
      margin: 0 0 30px;
    }

    &:last-child {
      @include media-breakpoint-down(sm) {
        border: none;
        margin: 0;
      }
    }
  }

  &__news-link {
    display: block;
    text-decoration: none;
  }

  &__news-date {
    font-size: 12px;
    line-height: 16px;
    color: $blue;
    margin: 0 0 10px;

    @include media-breakpoint-down(sm) {
      line-height: 17px;
    }
  }

  &__news-inner {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__news-content {
    width: calc(100% - 247px);
    padding: 0 30px 0 0;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0;
    }
  }

  &__news-subtitle {
    display: block;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: $mine-shaft;
    margin: 0 0 10px;
  }

  &__news-text {
    font-size: 16px;
    line-height: 25px;
    color: #666;
    margin: 0 0 10px;
    height: 48px;

    @include media-breakpoint-down(sm) {
      line-height: 28px;
      margin: 0 0 15px;
      height: auto;
    }

    em {
      font-weight: bold;
      font-style: normal;
    }
  }

  &__news-type-likes-comments {
    display: flex;
  }

  &__news-type {
    font-size: 11px;
    line-height: 12px;
    color: $blue;

    span {
      display: inline-block;
      vertical-align: top;
      padding: 4px 10px;
      background: #e1f6fa;
    }
  }

  &__news-likes-and-comments {
    margin-left: auto;
  }

  &__news-image-holder {
    width: 250px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__news-image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__more-news {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 20px;
    color: $white;
    background-color: #3f3f3f;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .3s ease;

    &:hover {
      background-color: #666;
    }
  }
}