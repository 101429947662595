@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.hero {
  position: relative;
  display: flex;
  height: 160px;
  align-items: center;
  justify-content: center;

  &__button-container-wrapper {
    text-align: center;
  }

  &__button-container {
    display: flex;
    padding: 0 25px;

    > label {
      padding: 20px;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 5px;
    }
  }

  &__image-orientation-select-container {
    margin-bottom: 23px;

    .radio__label {
      color: $black85;
    }
  }

  &__image-orientation-select-title {
    margin: 27.5px 0 8.5px;
    color: $black85;
    font-family: 'Noto Sans', 'Microsoft JhengHei', sans-serif !important;
    font-size: 18px !important;
    line-height: 1.33 !important;
  }

  &__image-container {
    position: relative;

    button:hover {
      opacity: 1;
    }
    button {
      border: none;
    }
    &--editbutton {
      opacity: 0;
    }
    &:focus-within &--editbutton {
      opacity: 1;
    }

    &:hover &--editbutton {
      opacity: 1;
    }

    img {
      position: relative;
      z-index: 0;
      width: 100%;
    }
  }

  .icon--plus {
    width: 24px;
    height: 24px;

    .plus {
      fill: none;
      stroke: $black;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 1.5px;
    }

    .mask {
      display: none;
    }
  }
}
