@import 'styles/colors.scss';

.md-block-image img {
  width: auto;
  max-height: 610px;
}

figure.image-landscape,
figure.image-portrait {
  margin: 0;
}

figcaption {
  padding: 5px 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;
}
