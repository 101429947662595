@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

body {
  font-family: 'Noto Sans', 'Microsoft JhengHei', sans-serif;
  font-size: 16px;
}

h1:not(.MuiTypography-root),
h2:not(.MuiTypography-root),
h3:not(.MuiTypography-root) {
  margin-top: 0;
  font-family: 'Noto Sans Bold', sans-serif;
  font-weight: normal;
}

p:not(.MuiTypography-root) {
  margin-top: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
}

h1:not(.MuiTypography-root),
.h1 {
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.953rem;
  font-weight: normal;
  line-height: 1.161290323;

  &.h1--huge {
    font-size: 2.441rem;
    line-height: 1.153846154;
  }
}

h2:not(.MuiTypography-root),
.h2 {
  font-size: 1.563rem;
  line-height: 1.2;
}

h3:not(.MuiTypography-root),
.h3 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h4:not(.MuiTypography-root),
.h4 {
  margin-bottom: 0.82rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.preamble {
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

.good-to-know{
  min-height: 165px;
  max-width: 314.5px;
}

strong {
  font-weight: bold !important;
}

.content {
  .md-inline-highlight {
    padding: 0;
  }

  a {
    color: $blue;
  }

  br + br + p {
    display: block;
    margin: 1.375rem 0;
    font-weight: bold;
  }

  strong {
    display: inline;
  }

  p:not(.MuiTypography-root) {
    margin-bottom: 1rem;
    line-height: 1.75rem;
  }

  ol,
  ul {
    padding-left: 25px;
    line-height: 1.75;

    ol {
      padding-left: 18px;
    }

    li {
      padding-left: 5px;
      margin-bottom: 0.6em;
    }

    li li {
      font-size: 0.8125rem;
    }
  }

  dl {
    dt {
      margin-bottom: 0.2em;
      font-weight: 600;
    }

    dd {
      margin-bottom: 1em;
      margin-left: 0;
    }
  }

  table {
    display: block;
    width: 100%;
    padding: 10px 0;
    overflow-x: auto;
  }

  td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    word-break: normal;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid $gallery;
    text-align: left;
    vertical-align: bottom;
    word-break: normal;

    p {
      font-weight: bold;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  blockquote {
    padding: 10px 0 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #4ca8de;
    background-color: #e2f2ff;
    color: #555;
    font-size: 1.2em;
    line-height: 1.75;
  }
}

.custom-ul {
  padding-left: 25px;
  margin-bottom: 30px;

  ul {
    padding-left: 18px;
    margin-top: 1em;
  }

  li {
    position: relative;
    margin-bottom: 1em;
    font-weight: 300;
    line-height: 1.5;
    list-style-type: none;

    &::before {
      position: absolute;
      top: 8px;
      left: -19px;
      display: block;
      width: 7px;
      height: 7px;
      background-color: $blue;
      content: '';
    }
  }

  li li {
    font-size: 0.8125rem;

    &::before {
      left: -17px;
      width: 9px;
      height: 9px;
    }
  }
}

hr {
  border-style: solid;
  border-color: $gallery;
  border-bottom: 0;
}

.color-text {
  color: $text-color !important;
}

.color-red {
  color: $red !important;
}

.color-blue {
  color: $blue !important;
}

.color-light-blue {
  color: #b4e6f2;
}

.color-white {
  color: $white !important;
}

@include media-breakpoint-down(md) {
  .sm-color-text {
    color: $text-color !important;
  }

  .sm-color-red {
    color: $red !important;
  }

  .sm-color-blue {
    color: $blue !important;
  }

  .sm-color-light-blue {
    color: #b4e6f2;
  }

  .sm-color-white {
    color: $white !important;
  }
}

a {
  color: $text-color;
}

.no-text-decoration {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.text--left {
  text-align: left;
}
