@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.divider-box {
  padding: 48px 43px 48px 53px;
  background-color: $near-bondi-blue;

  &--blue-bg {
    background-image: linear-gradient(to left, $near-bondi-blue, #034b70);
  }

  &--gray-bg {
    background-image: linear-gradient(to left, #7e7e7e, #4c4c4c);
  }

  @include media-breakpoint-up(md) {
    padding: 60px 24px 60px;
  }

  &__content {
    position: relative;
    margin: 0 auto;
    color: $white;

    &::before {
      position: absolute;
      top: -3px;
      left: -24px;
      width: 4px;
      height: 38px;
      background-color: $white;
      border-radius: 1px;
      content: "";
    }

    @include media-breakpoint-up(md) {
      &::before {
        top: 4px;
        left: -34px;
        height: 50px;
      }
    }

    h1 {
      margin-top: 40px;
      font-family: 'Noto Sans', sans-serif;
      font-size: 32px;
      line-height: 36px;

      &:nth-child(1) {
        margin-top: 0;
      }

      a, a:hover {
        padding: 0;
        margin: 0;
        color: inherit;
        cursor: default;
        font-size: inherit;
        line-height: inherit;
        overflow-wrap: inherit;
        text-decoration: inherit;
        word-wrap: inherit;
      }

      @include media-breakpoint-up(md) {
        font-size: 42px;
        line-height: 63px;
      }
    }

    p, li {
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    *:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__link {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}