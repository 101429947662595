@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.accordion-modal {
  &__title {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}
