@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.user-image {
  display: inline-block;

  &__circle {
    background-color: #f4f4f4;
    border-radius: 50%;

    &--icon,
    &--initials {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      color: $blue;
    }

    &--portrait {
      background-position-x: center;
      background-position-y: top;
      background-size: cover;
    }

    &--icon {
      svg {
        width: 18px;
        fill: #58c6e3;
      }
    }
  }

  &--small {
    .user-image__circle {
      width: 40px;
      height: 40px;
      font-size: 1rem;

      svg {
        width: 18px;
      }
    }
  }

  &--medium {
    .user-image__circle {
      width: 60px;
      height: 60px;
      font-size: 1.5rem;

      svg {
        width: 25px;
      }
    }
  }

  &--large {
    .user-image__circle {
      width: 75px;
      height: 75px;
      font-size: 2rem;

      svg {
        width: 35px;
      }
    }
  }

  &--desktop-x-large {
    @include media-breakpoint-up(md) {
      .user-image__circle {
        width: 150px;
        height: 150px;
        font-size: 4rem;

        &--icon svg {
          width: 70px;
        }
      }
    }
  }

  &--desktop-huge {
    @include media-breakpoint-up(md) {
      .user-image__circle {
        width: 176px;
        height: 176px;
        font-size: 4.5rem;

        &--icon svg {
          width: 75px;
        }
      }
    }
  }
}
