@import 'styles/breakpoints.scss';

.gateway-block-item {
  position: relative;

  &__link {
    display: flex;
    color: #fff;
    text-decoration: none;

    &:hover {
      .gateway-block-item__circle {
        background: #fff;
      }
    }

    @include media-breakpoint-up(md) {
      width: 180px;
      flex-direction: column;
      align-items: center;
    }
  }

  &:nth-last-child(1) {
    .gateway-block-item__line {
      display: none;
    }
  }

  &:last-of-type {
    .gateway-block-item__icon {
      display: none;
    }
  }

  &__markline {
    position: relative;
    top: 11px;
    display: flex;
    width: 17px;
    min-width: 17px;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    @include media-breakpoint-up(md) {
      left: calc(50% - 12px);
      width: 100%;
      flex-direction: row;
      align-items: center;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__circle {
    display: block;
    width: 17px;
    min-width: 17px;
    height: 17px;
    min-height: 17px;
    margin-bottom: 6px;
    border: 1px solid #fff;
    border-radius: 50%;
    transition: 0.1s ease-in-out;

    @include media-breakpoint-up(md) {
      width: 25px;
      min-width: 25px;
      height: 25px;
      min-height: 25px;
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  &__line {
    display: block;
    width: 1px;
    height: 100%;
    margin-bottom: 6px;
    background: #fff;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 1px;
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 38px;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding: 0 15px;
      font-size: 28px;
      line-height: 64px;
      text-align: center;
    }
  }

  &__preamble {
    padding-bottom: 30px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding: 0 15px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }

  &__content {
    width: 100%;
  }

  &__icon {
    display: none;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      right: -5px;
      display: block;
      transform: translateY(50px);
    }
  }
}
