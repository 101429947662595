@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';
@import 'styles/helpers.scss';

.user-find-selector.search-selector {
  height: 40px;
  border: 1px solid $text-color;
  border-bottom-color: $text-color;

  .icon--search {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
  }
}

.user-find-selector .search-selector {
  &__result {
    display: flex;
    height: 100%;
  }

  &__selection {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0 2px 0 10px;
    margin: 5px 2px 5px 10px;
    background-color: #e1f6fa;
    border-radius: 5px;
    color: $cerulean;
    cursor: default;
    font-size: 0.75em;
    white-space: nowrap;

    .icon--cancelsmall {
      width: 24px;
      height: 24px;
      padding: 8px;
      margin-left: 2px;
      cursor: pointer;
      fill: $cerulean;
    }

    &:hover {
      background-color: $bondi-blue;
      color: #b4e6f2;

      .icon--cancelsmall {
        fill: #b4e6f2;
      }
    }
  }

  &__hidden-input {
    width: 100%;
    height: 38px;
    margin-left: 2px;
    border: none;
  }

  &__autosuggest {
    &__input {
      height: 38px;
    }

    &__input--focused {
      box-shadow: 0 0 3px $cerulean;
    }

    &__suggestions-container {
      width: auto; // unset
    }

    &__suggestions-list {
      margin-top: 1px;

      .text {
        white-space: nowrap;
      }
    }
  }
}
