@import 'styles/colors.scss';

progress[value].progress-bar__bar {
  display: inline-block;
  width: 100%;
  height: 2px;
  // FF-border
  border: none;
  // IE-color
  color: $cerulean;
  background-color: $white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0;
  }

  &::-moz-progress-bar {
    background-color: $white;
    border-radius: 0;
  }

  &::-webkit-progress-value {
    background-color: $cerulean;
    border-radius: 0;
  }
}

progress.progress-bar__bar {
  -webkit-writing-mode: horizontal-tb;
}


.upload-entry__container:hover {
  progress[value].progress-bar__bar::-webkit-progress-bar {
    background-color: $blue20;
  }
}
