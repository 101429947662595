@import 'styles/breakpoints.scss';

.navigation-wrapper {
  &--mobile {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
