@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.profile-modal {
  width: 100%;
  padding: 20px;
  border-collapse: collapse;

  @include media-breakpoint-up(md) {
    min-height: 750px;
    padding: 20px 2.5rem 0;
  }

  &__preamble {
    color: #333;
    font-size: 1.125rem;
    line-height: 1.4;

  }

  &__user-image {
    margin: 10px 0;

    @include media-breakpoint-up(md) {
      margin: 25px 0;
    }
  }

  &__user-label {
    color: $dark-gray;
    font-size: 1.125rem;
  }

  &__info {
    text-align: left;

    tr {
      display: block;
      margin-bottom: 20px;

      @include media-breakpoint-up(sm) {
        display: table-row;
        margin-bottom: 0;
      }
    }

    td {
      display: block;

      + td {
        @include media-breakpoint-up(sm) {
          padding-left: 10px;
          text-align: right;
        }
      }

      &:first-child {
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-weight: 800;
        line-height: 1.4;
      }

      &:last-child {
        word-break: break-all;
      }

      @include media-breakpoint-up(sm) {
        display: table-cell;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid #f2f2f2;
      }
    }

    @include media-breakpoint-up(sm) {
      min-width: 410px;
      margin: 0 auto;
    }
  }

  &__settings {
    text-align: left;

    @include media-breakpoint-up(sm) {
      min-width: 410px;
      margin: 0 auto;
    }
  }

  &__label {
    color: $semi-black;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.4;

  }

  &__control {
    width: 50%;
    color: $semi-black;

    @include media-breakpoint-up(xxs) {
      width: 70%;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__continue {
    display: block;
    margin: 0 auto;
  }

  + .modal__footer {
    min-height: 140px;

    p {
      font-family: 'Noto Sans', sans-serif;
      font-size: 0.875rem;
    }
  }
}
