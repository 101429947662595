@import 'styles/breakpoints.scss';

.tree {
  ul {
    padding: 0;
  }

  overflow: hidden;
  margin-top: 25px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &__list {
    padding: 0;
    margin: 0;

    &--children {
      + .tree-item {
        > span {
          box-shadow: 0 0 5px 2px rgba(63, 63, 63, 0.32);
        }
      }
    }
  }
}
