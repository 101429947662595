@import 'styles/breakpoints.scss';

.not-found {
  &__content {
    max-width: 720px;
    min-height: 300px;
    margin: 20px 20px;
    box-shadow: 0 3px 50px rgba(0, 0, 0, 0.16);

    @include media-breakpoint-up(sm) {
      width: 100%;
      margin: 60px auto;
    }
  }

  &__wrapper {
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
  }

  &__image {
    max-width: 260px;
    margin: 30px auto;
  }
}
