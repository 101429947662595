.language-select-item {
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 12px;

  &__checkmark {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    background: url('./check.svg') no-repeat;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
