@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.search {
  width: 100%;
  max-width: 312px;
  margin: 0 auto;
  color: $white;
}

.search__form {
  position: relative;

  &+div {
    color: $white;
  }

  .icon--search {
    position: absolute;
    top: 20px;
    right: 0;
    width: 30px;
    height: 30px;

    path {
      fill: $white;
    }

    @include media-breakpoint-up(md) {
      top: 20px;
    }
  }

  h4 {
    margin: 10px 0;
    font-size: 1.5rem;
  }
}

.react-autosuggest {
  &__container {
    position: relative;
    color: $white;
  }

  &__input {
    width: 100%;
    padding: 10px 30px 10px 10px;
    border: none;
    border-bottom: 2px solid $white;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 0;
    color: $white;
    font-family: inherit;
    font-size: 1.75rem;
    line-height: 1.4em;
    outline: none;

    @include media-breakpoint-up(md) {
      padding: 6px 40px 6px 20px;
      font-size: 2rem;
    }

    &::-webkit-input-placeholder {
      color: #f7f7f7;
    }
  }

  &__input--focused {
    color: $white;
    outline: none;

    &::-webkit-input-placeholder {
      color: $black60;
    }
  }

  &__suggestions-container {
    display: none;
  }

  &__suggestions-container--open {
    z-index: 2;
    display: block;
    padding: 8px 0;
    margin-top: 20px;
    font-size: 15px;
  }

  &__suggestions-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    .highlight {
      font-family: 'Noto Sans Bold', sans-serif;
    }
  }

  &__suggestion {
    padding: 10px;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding: 10px 10px 10px 20px;
    }

    &--highlighted {
      background: rgba(255, 255, 255, 0.1);
    }

    p {
      margin-bottom: 10px;
      color: $white;
      font-family: 'Noto Sans', sans-serif;
      font-size: 1.5rem;
      line-height: 1;

      &.suggestion-content__meta {
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
      }
    }
  }
}
