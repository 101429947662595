.action-menu {
  position: absolute;
  top: 100%;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0.5rem;
  background: #FFFFFF;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 2rem rgba(26, 26, 26, 0.12);
  min-width: 200px;
  z-index: 3;
  gap: 0.5rem;
  font-size: 0.875rem
}

.action-menu-item {
  > button {
    border: none;
    background: inherit;
    color: inherit;
    width: 100%;
    text-align: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  &:hover {
    background: #F1F7F9;
  }
  &--critical {
    color: #BA1A1A;
  }
}