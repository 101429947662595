@import "styles/colors.scss";

.file-uploader {
  &__container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    padding: 35px;
  }

  &__files-area {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    overflow-y: auto;
  }

  &__drop-area {
    padding: 10px;
    min-height: 150px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background-image: linear-gradient(to right, #333 50%, transparent 50%), linear-gradient(to right, #333 50%, transparent 50%), linear-gradient(to bottom, #333 50%, transparent 50%), linear-gradient(to bottom, #333 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px;
  }
  &__success-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  &__drag-n-drop-files-message {
    padding: 20px 0 5px;
    margin: auto;
  }

  &__info-message {
    max-width: 700px;
    font-size: 14px;
  }

  &__buttons-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-self: flex-end;
    padding: 20px 0 5px;
    margin: auto -7px 0 0;
  }

  &__button {
    display: inline-block;
    padding: 5px 15px;
    border: none;
    margin: 12px 7px;
    background-color: $dark-gray;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background-color: change-color($dark-gray, $alpha: 0.8);
    }

    &--light {
      border: 1px solid $dark-gray;
      background-color: $white;
      color: $dark-gray;

      &:hover {
        background-color: change-color($dark-gray, $alpha: 0.1);
      }
    }

    &--align-left {
      margin-right: auto;
    }

    &--disabled {
      &, &:hover {
        background-color: change-color($dark-gray, $alpha: 0.6);
        color: $white;
        pointer-events: none;
      }
    }
    
    &--spinner {
      position: relative;
      height: 10px;
      padding: 0px 8px 0px 8px;
      top: -8px;
    }
  }

  &__entry {
    &-container {
      padding: 7px 0;
      width: 100%;
      display: flex;
      padding-left: 3px;
      flex-direction: row;
      align-items: stretch;

      &:hover {
        background-color: $blue20;
        border-left: 3px solid $blue90;
        padding-left: 0;
      }
    }

    &-icon {
      max-height: 30px;
      width: auto;
    }

    &-column {
      display: flex;
      cursor: pointer;

      &:first-child {
        width: 20px;
        margin: auto 15px;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }

      &:last-child {
        padding: 0 7px;
        margin-left: auto;
      }
    }

    &-icon,
    &-filename,
    &-size,
    &-date {
      display: inline-block;
      margin: auto 0;
    }

    &-size {
      margin-right: 7px;
    }

    &-filename:hover {
      color: $blue90;
      text-decoration: underline;
    }
  }

  &__drag-n-drop-files-message p {
    cursor: pointer;
  }
}
