@import "styles/colors.scss";

.file-upload-modal {
  &__wrapper {
    position: fixed;
    z-index: 12;
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
  }

  &__container {
    z-index: 12;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 6px 1px rgba(120, 120, 120, 0.3);
  }

  &__button {
    display: inline-block;
    padding: 5px 15px;
    border: none;
    margin: 12px 7px;
    background-color: $dark-gray;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background-color: change-color($dark-gray, $alpha: 0.8);
    }
  }

  &__click-outside-handler {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color:rgba(120, 120, 120, 0.3);
  }
}
