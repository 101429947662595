@import 'styles/colors.scss';

.portal-mega-menu {
  position: absolute;
  top: 100%;
  background: $blue5;

  &__gateway-link {
    color: $white;
    text-decoration: none;
  }

  &__gateway-image {
    padding-top: 60%;
    margin-bottom: 20px;
    background: #074355 no-repeat center;
    background-size: cover;
  }

  &__gateway-title {
    margin-bottom: 6px;
    font-family: 'Noto sans', sans-serif;
    font-size: 28px;
    line-height: 35px;
  }

  &__gateway-preamble {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }

  &__container {
    padding: 40px 0 20px 0;
    color: $semi-black;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__col-wrapper {
    display: flex;
  }

  &__links-wrapper {
    overflow: hidden;
    width: 100%;
  }

  &__column {
    min-width: 230px;
    max-width: 230px;
    margin: 0 20px;
  }

  &__column-divider {
    width: 1px;
    min-width: 1px;
    margin: 0 39px 20px 19px;
    background: $blue;
  }

  &__item {
    display: block;
    color: $semi-black;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    opacity: 0.95;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }

    &--large {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
    }

    &--active {
      text-decoration: underline;
    }
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__submenu {
    width: 240px;
    margin-bottom: 20px;
  }

  &__close-wrapper {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  &__close-icon {
    width: 20px;
    height: 20px;
    margin-top: 0;
    cursor: pointer;
    fill: white;
  }
}
