@import 'styles/colors.scss';

.article-meta {
  &__date {
    display: block;
    margin-bottom: 5px;
    color: $dusty-gray;
    font-size: 0.75rem;
    line-height: 1.75;
  }
}
