@import '../SideButtons.scss';

.embed-mspowerbi-button__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.embed-mspowerbi-button__label {
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0;
  width: 68px;
}

.embed-mspowerbi-button__container button {
  border: 0;
  background-color: $white;
  height: 50px;
  display: flex;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
  > svg > path {
    stroke-width: 0.75;
    stroke: #3f3f3f;
  }
}

