@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.article-list-block-box {
  display: flex;
  align-items: center;
  padding: 30px 0;
  background-color: $white-lilac;

  @include media-breakpoint-up(md) {
    padding: 62px 24px;
  }

  &__content {
    position: relative;
    margin: 0 auto;

    *:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__title-container {
    display: flex;
    width: 1150px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0 20px;
    list-style-type: none;

    @media screen and (max-width: 1208px) {
      width: auto;
      min-width: 0;
      justify-content: center;
    }
  }

  &__title {
    position: relative;
    display: inline-block;
    overflow: hidden;
    min-width: 100%;
    color: $black95;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 18px !important;
    line-height: 30px !important;
    text-align: left;
    
    &, &:nth-last-child(1) {
      margin: 0 0 16px 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 20px 0;

        @media screen and (max-width: 1230px) {
          margin: 0 10px 20px 10px;
        }
      }
    }

    a, a:hover {
      padding: 0;
      margin: 0;
      color: inherit;
      cursor: default;
      font-size: inherit;
      line-height: inherit;
      overflow-wrap: inherit;
      text-decoration: inherit;
      word-wrap: inherit;
    }

    @include media-breakpoint-up(sm) {
      min-width: 560px;
      max-width: 560px;
    }
  }

  &__links {
    display: flex;
    width: 1150px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0 20px;
    list-style-type: none;

    @media screen and (max-width: 1230px) {
      width: auto;
      min-width: 0;
      justify-content: center;
    }
  }

  &__link {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__link-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 20px;
  }

  &__link-button {
    display: inline-block;
    width: auto;
    padding: 5px 1.4rem;
    border: 1px solid $semi-black;
    border-radius: 3px;
    color: $semi-black;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 37px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none;
    transition: 0.3s ease;
    word-wrap: break-word;

    &:hover {
      border-color: $black75;
      color: $black75;
    }

    &:active {
      border-color: $semi-black;
      background: $gallery;
      color: $semi-black;
    }
  }
}
