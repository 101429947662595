@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.notifications {
  position: relative;

  &__view {
    position: absolute;
    width: 420px;
    background-color: white;
    z-index: 100;
    right: 0;
    top: 100%;
    box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e4ebed;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &--list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &--title {
      font-family: 'Noto Sans', sans-serif;
      padding: 20px;
      border-bottom: 1px solid #e4ebed;
      display: flex;
      justify-content: space-between;
    }
  }

  &__icon-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    min-width: 40px;
    height: 40px;
    background-color: #FAFAFA;


    &:hover {
      cursor: pointer;
    }

    &--new-notification {
      &:hover {
        background-color: #F9F2F2;
      }
    }
  }
}

.notifications__empty {
  padding: 40px 0;
  text-align: center;
  font-size: 0.9em;
}

.notification-item {
  border-bottom: 1px solid #e4ebed;
  padding: 20px;
  font-size: 0.9rem;
  position: relative;

  .notification-item__type {
     font-weight: bold;
     margin-left: 3px;
  }

  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #f4f4f4;
    padding: 3px;
    color: #9b9b9b;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    top: -5px;
    left: -5px;
    cursor: pointer;

    .icon__cancelsmall {
      color: #9b9b9b;
    }
  }

  &__timestamp {
    color: #9b9b9b;
  }

  &__top {
    display: flex;
    padding-bottom: 10px;
  }

  &__comment {
    svg {
      fill: $orient;
    }
    width: 18px;
    margin-right: 20px;
  }

  &__title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    margin-left: 40px;
  }

  &__title {
    margin-left: 40px;
    font-family: 'Noto Sans', sans-serif;

    &--link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__body {
    margin-top: 5px;
    margin-left: 40px;
    font-family: 'Noto Sans', sans-serif;
    font-style: italic;
    word-wrap: break-word;
  }

  &__not-yet-read {
    background-color: #e1f6fa;
  }

  &__footer, &__body {
    margin-left: 40px;
  }
}

.notifications-manage-my-content {
  display: block;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: #32c5ff;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.mark-as-read {
    display: inline-block;
    font-size: 0.7rem;
    font-family: 'Noto Sans', sans-serif;
    color: #32c5ff;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
