@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.title {
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    border-radius: 6px;
    content: '';
  }

  > * {
    width: 100%;
    padding: 8px 0;
    margin: 0;
    font-family: 'Noto Sans Bold', sans-serif;
    font-size: 1.25rem;
    word-break: break-word;
  }

  &__size {
    &--small {
      padding-left: 15px;
      margin: 30px 0 15px;

      &::before {
        width: 3px;
      }

      > * {
        font-size: 1rem;
      }
    }

    &--medium {
      padding-left: 19px;
      margin: 30px 0;
    }
  }

  &__spacing {
    &--no-top-margin {
      margin-top: 0;
    }
  }

  &--color-white {
    color: $white;
  }

  &--color-black {
    color: $text-color;
  }

  &--mobile-color-black {
    @include media-breakpoint-down(sm) {
      color: $text-color;
    }
  }

  &--blue::before {
    background-color: $blue;
  }

  &--red::before {
    background-color: $red;
  }

  &--green::before {
    background-color: $green;
  }

  &--orange::before {
    background-color: #f64812;
  }

  &--portal-related::before {
    .aadw-innovation-portal & {
      background-color: #f64812;
    }

    .aadw-strategy-portal & {
      background-color: $blue;
    }

    .aadw-codeofconduct-portal & {
      background-color: $blue;
    }
  }

  &__stories {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 10px;

    h1 {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }
  }

}

.box .title {
  padding-bottom: 1.875rem;
  padding-left: 0;
  margin: 0;

  &::before {
    content: none;
  }

  h1,
  h2 {
    padding: 0;
  }

  h1 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
  }

  h2 {
    padding: 0;
  }

  h3 {
    padding: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.125rem;
  }
}
