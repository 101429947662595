@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.document-icon {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    width: 72px;
    height: 72px;
    margin: 0;
  }

  &__center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    text-align: center;
    font-size: 13px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      width: 26px;
      height: 26px;
    }
  }

  &__text {
    color: #FFFFFF;
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }

  }

  &__color--red {
    background-color: $icon-red;
  }
  &__color--orange {
    background-color: $icon-orange;
  }
  &__color--green {
    background-color: $icon-green;
  }
  &__color--blue {
    background-color: $icon-blue;
  }
  &__color--grey {
    background-color: $icon-grey;
  }
  &__color--backup {
    background-color: $icon-backup;
  }
}

