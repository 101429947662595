@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.author-display {
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    border: 0;
    background: #cbcbcb;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;

    &:hover {
      opacity: 1;
    }
  }

  &__portrait {
    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
  }

  &__portrait-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.75rem;
    line-height: 1.67;

    &:hover {
      .author-display__button {
        display: block;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;
      font-size: 1rem;
      line-height: normal;
      text-align: center;
    }
  }

  &__info-wrapper {
    width: 100%;
    margin-left: 15px;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  &__name {
    display: block;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-family: 'Noto Sans', sans-serif;
      font-size: 1.25rem;
      font-weight: 300;
    }
  }

  &__title {
    display: block;
    margin: 0;
    color: $dusty-gray;

    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
    }
  }

  &__description {
    flex-grow: 1;
    padding-top: 1rem;
    border-top: 1px solid $gallery;
    font-size: 0.75rem;
    white-space: pre-wrap;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }

    &__input {
      min-height: 200px;
      cursor: text;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  &__description-text {
    margin: 0;
    font-size: 0.75rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}
