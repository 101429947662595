@import 'styles/colors.scss';

$horizontal-sidebar-button-padding: 12.5px;

.md-sb-button {
  width: auto;
  height: 50px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: #fff;
  border-radius: 0;
  color: $white;
  transition: none;
  vertical-align: middle;
}

.md-editor-toolbar {
  z-index: 9;
  background-color: #333;
  border-radius: 0;
  box-shadow: none;
}

.md-RichEditor-styleButton:hover,
.md-RichEditor-styleButton:focus,
.md-RichEditor-activeButton {
  color: $blue90;
}

[class*='hint--']::after {
  box-shadow: none;
  font-family: inherit;
  font-size: 13px;
}

.md-RichEditor-controls {
  font-family: inherit;
  font-weight: 500;
}

.md-sb-button.md-add-button {
  height: 24px;
  width: 24px;
  background: #333;
  background: url('./plus.svg');
  background-position: 0;
  background-repeat: no-repeat;
  border-radius: 0;
  transition: none;

  svg {
    display: none;
  }

  &:hover,
  &.md-open-button {
    background: url('./plusBlack.svg');
    background-position: 0;
    background-repeat: no-repeat;
  }

  &:hover::after {
    position: absolute;
    bottom: 0;
    left: 30px;
    padding-left: 10px;
    background-color: $white;
    color: #545454;
    content: 'Add image or video';
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 800;
    line-height: 23px;
    text-align: left;

    @-moz-document url-prefix() {
      bottom: 2px;
    }
  }

  &.md-open-button {
    &::after {
      content: '';
    }

    + div {
      position: absolute;
      top: -60px;
      left: -10px;
      display: flex;
      flex-wrap: wrap;
      padding: 12px 8px;
      background-color: white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.rich-text__library-edit .md-sb-button.md-add-button {
  &:hover::after {
    content: 'Add image, video, accordion or form';
  }
}
.rich-text__news .md-sb-button.md-add-button {
  &:hover::after {
    content: 'Add image, video or form';
  }
}
.rich-text__blog .md-sb-button.md-add-button {
  &:hover::after {
    content: 'Add image, video or form';
  }
}
.rich-text__topic .md-sb-button.md-add-button {
  &:hover::after {
    content: 'Add image, video or quiz';
  }
}

.md-sb-button,
.md-add-button.md-open-button {
  transform: none;
}

.md-side-toolbar {
  z-index: 9;
  left: -37px;
  cursor: pointer;
  transform: none;
  width: 100%;

  & > span {
    position: absolute;
    bottom: 40px;
    left: -10px;
    display: flex;
    width: 90px;
    justify-content: space-around;
    background: #333;

    button:hover svg path,
    button:hover svg circle,
    button:focus svg path,
    button:focus svg circle {
      fill: $blue90 !important;
    }

    &::before {
      position: absolute;
      bottom: -10px;
      left: 12px;
      width: 0;
      height: 0;
      border-top: 10px solid #333;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      content: '';
    }
  }
}
