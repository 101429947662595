@import 'styles/breakpoints.scss';

.survey-popup {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  max-width: 456px;
  max-height: calc(100% - 2rem);
  z-index: 1010;
  margin: 1rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(26, 26, 26, 0.12);
  background: #fff;
  overflow: auto;

  h2 {
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.25rem;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    padding: 1rem 0 1.5rem 0;

    svg {
      height: 36px;
      width: 36px;
      margin-right: 0.75rem;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;

    button, a {
      flex-grow: 1;
      font-size:  1rem;
      font-weight: 600;
      text-align: center;
    }
  }
} 