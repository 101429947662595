@import 'styles/breakpoints.scss';

//will be removed after old logo component deprecation
.app-switch-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    height: 40px;
  }
}

.app-switch {
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: fixed;
    width: 294px;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    z-index: 30;
    display: none;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50px;
      width: auto;
      min-width: 220px;
    }

    &--opened {
      display: block;
      box-shadow: 0 2px 5px rgba(63, 63, 63, 0.32);
    }

    .app-switch-item__text {
      margin-right: 0;
    }
  }
}
