@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.footer {
  padding: 30px 0;
  background-color: $text-color;
  color: $gallery;
  font-size: 0.875rem;
  outline: 2px solid $text-color;

  > .container {
    @include media-breakpoint-up(xl) {
      padding: 28px 0;
      border-top: 2px solid #7d7d7d;
    }

    &.public-page {
      border-top: none;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  &__icons {
    width: 100%;
    max-width: 200px;
    padding: 0;
    margin: 0 0 20px 0;

    @include media-breakpoint-up(xl) {
      order: 2;
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0 0 30px 0;
    font-size: 0.75rem;
    font-weight: 700;
    
    @include media-breakpoint-up(xl) {
      &.public-page {
        display: flex;
      }

      display: none;
      order: 3;
      margin-bottom: 0;
    }
  }

  &__links-bottom-row {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;

      .link-item {
        border-left: 1px solid $gallery;
      }
    }
  }

  &__assa-text {
    margin-bottom: 10px;

    @include media-breakpoint-up(xl) {
      order: 4;
      margin-bottom: 0;
    }
  }

  &__copyright {
    font-size: 0.75rem;

    @include media-breakpoint-up(xl) {
      order: 1;
    }
  }
}
