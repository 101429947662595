@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.text-media-box {
  display: flex;
  flex-direction: column;
  background: $orient;

  &__content-col {
    display: flex;
    padding: 60px 25px 60px;
  }

  &__title {
    margin-bottom: 29px;
    font-family: 'Noto Sans Light', sans-serif;
    font-size: 20px;
    line-height: 32px;

    a, a:hover {
      padding: 0;
      margin: 0;
      color: inherit;
      cursor: default;
      font-size: inherit;
      line-height: inherit;
      overflow-wrap: inherit;
      text-decoration: inherit;
      word-wrap: inherit;
    }
  }

  &__content-wrapper {
    width: 100%;
    color: $white;
  }

  &__link {
    display: inline-table;
    width: auto;
    padding: 5px 1.4rem;
    border: 1px solid $white;
    border-radius: 3px;
    color: $white;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 37px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none;
    transition: 0.3s ease;
    word-wrap: break-word;
    margin-top: 1rem;

    &:hover {
      background: $white;
      color: $text-color;
    }

    &:active {
      background: #f1f1f1;
    }
  }

  &__image-col {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: lightgrey;

    .iframe-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      padding-top: 0;
    }
  }

  &__video-bg {
    background: #000000;
    background-image: none !important;
  }

  &__display-infographic-title {
    padding-top: 100px;
    margin-bottom: 30px;
    font-size: 72px !important;
    line-height: 1.2 !important;
    overflow-wrap: break-word;
    text-align: center;
    word-wrap: break-word;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      font-size: 96px !important;
    }

    a, a:hover {
      padding: 0;
      margin: 0;
      color: inherit;
      cursor: default;
      font-size: inherit;
      line-height: inherit;
      overflow-wrap: inherit;
      text-decoration: inherit;
      word-wrap: inherit;
    }
  }

  &__display-infographic-content{
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    min-height: 475px;

    &--reversed {
      flex-direction: row-reverse;
    }

    &__content-col {
      width: 50%;
    }

    &__image-col {
      width: 50%;
      > img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    
    &--inset-image {
      background: transparent;
      > img {
        position: absolute;
        height: 100%;
        width: unset;
        object-fit: contain;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content-col {
      padding: 60px 25px 107px;

      h2 {
        margin-bottom: 30px;
        font-size: 28px;
      }

      a {
        width: auto;
        line-height: 35px;
      }
    }

    &__title a {
      line-height: inherit;
    }
  }

  @include media-breakpoint-up(xl) {
    &__content-col {
      justify-content: flex-end;
      padding: 60px 0 107px;
    }

    &__content-wrapper {
      max-width: 59%;
      margin-right: 120px;
    }

    &--reversed &__content-col {
      justify-content: flex-start;
    }

    &--reversed &__content-wrapper {
      margin-right: 0;
      margin-left: 120px;
    }
  }
}
