@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';
@import 'styles/helpers.scss';

.search-selector {
  position: relative;
  z-index: 1;
  height: 28px;
  border: 1px solid #d6d6d6;
  border-bottom-color: #000;

  &--error {
    border: 1px solid $guardsman-red;
  }

  &--disabled {
    opacity: 50%;
  }

  &__autosuggest {
    &__container {
      position: relative;
    }

    &__input {
      width: 100%;
      height: 26px;
      padding: 0 40px 0 10px;
      border: none;
      -webkit-appearance: none;
      background-color: transparent;
    }

    &__suggestions-container {
      @include custom-scrollbar($gallery, $white);
      width: 320px;
    }

    &__suggestions-list {
      overflow: auto;
      max-height: 325px;
      padding: 0;
      margin-top: 10px;
      background-color: $white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      list-style: none;
      overflow-x: hidden;

      .highlight {
        font-weight: bold;
      }

      .text {
        overflow: hidden;
        padding: 0 10px;
        line-height: 1.5;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .email {
        color: $dusty-gray;
        font-size: 0.85em;
      }

      .container {
        display: flex;
        flex-direction: column;
      }
    }

    &__suggestions-list--large {
      @include media-breakpoint-up(xl) {
        max-height: 225px;
      }

      .container {
        @include media-breakpoint-up(xl) {
          flex-direction: row;
          align-items: center;

          .text {
            flex: 1;
          }
        }
      }
    }

    &__suggestion {
      padding: 10px 0;
      cursor: pointer;

      &--highlighted {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__result {
    height: 100%;
    margin-top: 5px;
  }

  &__selection {
    display: inline-flex;
    align-items: center;
    padding: 2px 2px 2px 10px;
    margin: 5px 2px 5px 0;
    background-color: #e1f6fa;
    border-radius: 5px;
    color: $cerulean;
    cursor: default;
    font-size: 0.75em;

    &--single {
      padding: 10px;
    }

    .icon--cancelsmall {
      width: 24px;
      height: 24px;
      padding: 8px;
      margin-left: 2px;
      cursor: pointer;
      fill: $cerulean;
    }

    &:hover {
      background-color: $bondi-blue;
      color: #b4e6f2;

      .icon--cancelsmall {
        fill: #b4e6f2;
      }
    }
  }
}
