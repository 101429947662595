.text-media-box-list {
  &__cta {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  &__item {
    position: relative;
  }

  &__cta-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__cta-link {
    color: #00a0d0;
  }
}
