@import 'styles/breakpoints.scss';

.info-highlight-wrapper {
    &__pre-body {
        width: 100%;
        margin-right: 40px;
    }
    
    > div {
        min-width: 0;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;  
    }
}

.info-highlight-box {
    flex: 0 0 calc(33% - 12px);
    flex-flow: column nowrap;
    font-size: 14px;
}