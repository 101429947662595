@import '../SideButtons.scss';
@import 'styles/colors.scss';

.embed-accordion {
  &__container {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
  }
    
  &__label {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 0;
  }

  &__button {
    border: 0;
    background-color: $white;
    height: 50px;
    display: flex;
    align-items: flex-end;

    &:hover {
      cursor: pointer;
    }
    
    &--icon {
      width: 42px;
      height: 42px;
      > path {
        stroke: #3f3f3f;
      }
    }
  }
}
