.comment-item {
    > .comment-content {
        margin-top: 1.5rem;
    }

    &__list {
        margin-top: 0.25rem;
        margin-left: 3.5rem;
        border-left: 1px solid #EBEBEB;
        padding-left: 0.5rem;

        > .comment-content {
            margin-top: 1rem;
        }
    }

    &__view-replies {
        border: none;
        background-color: unset;
        font-size: 0.813rem;
        color: #1E769C;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
    }
}