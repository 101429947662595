@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';


.same-topic {
  
  &__container {
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__item-container {
    margin-right: 40px;
    width: 100%;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: rgb(0, 92, 138);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @include media-breakpoint-up(md) {
      width: 33.3%;
      margin-top: 0;
    }

    &--margin {
      margin-top: 20px ;
      @include media-breakpoint-up(md) {
        margin-top: 0 ;
      }
    }
  }

  &__last-item-container {
    margin: 0;
    width: 100%;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: rgb(0, 92, 138);
    display: flex;
    flex-direction: column;
    margin-top: 20px ;
    @include media-breakpoint-up(md) {
      margin-top: 0 ;
      width: 33.3%;
    }
  }

  &__image-wrapper {
    position: relative;
    
  }

  &__gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(#ffffff00 0%, 60%, rgb(0, 92, 138));
    z-index: 1;
  }

  &__text-container {
    color: $white;
    position: relative;
    top: -40px;
    line-height: 1.5;
    margin: 0 15px 0 24px;
    z-index: 1;
  }

  &__created-on {
    font-family: 'Noto Sans Light', sans-serif;
    filter: blur(0.3px);
    font-size: 12px;
    height: 20px;
    font-weight: 300;
  }

  &__description {
    filter: blur(0.3px);
    font-family: 'Noto Sans', sans-serif;;
    font-size: 18px;
    height: 60px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 15px 15px 0;
  }

  &__topic-image-wrapper {
    height: 100%;
  }

  &__topic-header {
    font-size: 32px;
    overflow: hidden;
    text-align: center;
    filter: blur(0.3px);
    margin-bottom: 10px;
  }

  &__topic-description {
    font-size: 16px;
    filter: blur(0.3px);
  }

  &__topic-title {
    font-size: 16px;
    filter: blur(0.3px);
    min-height: 55px;
  }

  &__topic-preamble {
    font-size: 14px;
    filter: blur(0.3px);
  }

  &__link {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $black20;
    }
  }

  &__explore-topic-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $white;
    padding: 0 0 15px 0;
    bottom: 10px;
    left: 10px;
  }

  &__arrow-wrapper {
    position: relative;
    left: 15px;
    top: 7px;
  }

  &__original-image-wrapper {
    display: flex;
    justify-content: center;
    top: 5px;
  }
  &__original-image {
    max-height: 90%;
    position: absolute;
    top: 7%;
  }
  &__portrait-image {
    width: 100%;
  }
}
