@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.portal-main-menu {
  background-color: #074E74;
  &__level {
    &--second {
      overflow: hidden;
      background-color: #034b70;
    }
  }

  &__before-hover-overlay,
  &__after-hover-overlay {
    position: absolute;
    bottom: 0;
    width: 3%;
    height: 110px;
    list-style-type: none;
  }

  &__before-hover-overlay {
    right: 100%;
  }

  &__after-hover-overlay {
    left: 100%;
  }

  &__items {
    position: relative;
    align-items: center;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    max-height: 60px;
  }
  
  &__item {
    position: relative;
    width: max-content;
    list-style-type: none;
    
    .portal-menu-item {
      color: $people-search-background;

      &--active {
        color: $people-search-background;

        path {
          fill: $neutral-0;
        }
      }
      path {
        fill: $people-search-background;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &__overflow {
    height: 60px;
    max-height: 60px;
    
    &--second-level {
      height: 48px;
      max-height: 48px;
    }

    [data-overflow-viewport] {
      // For IE
      -ms-overflow-style: none;
      // Use of !important since component does not expose an option for
      // overflow appearance styling.
      overflow-y: hidden !important;
      // For Safari and Chrome 
      &::-webkit-scrollbar {
        display: none;
      }
      // For Firefox
      scrollbar-width: none;
    }
  }

  &__scroll-button {
    
    position: absolute;
    z-index: 12;
    right: 0;
    bottom: 6px;
    width: 32px;
    height: 44px;
    border: none;
    background-color: #074E74;

    svg > path {
      fill: $people-search-background;
    }

    svg > g > path {
      fill: $people-search-background;
    }

    &:hover {
      cursor: pointer;
    }

    &--second-level {
      display: inline;
      bottom: 0;
      background-color: #034b70;

      svg > path {
        fill: $white;
      }
  
      svg > g > path {
        fill: $white;
      }
    }
  }
}
