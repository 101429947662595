@import 'styles/breakpoints.scss';

.sr-only {
  position: absolute;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.channels-screen-selector__container {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}