@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';
@import 'styles/colors.scss';

.insights-database-header-box {
  position: relative;
  padding: 20px 25px 25px;
  background-color: $orient;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: $white;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 60px 30px 30px;
  }

  &__background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__background-inner {
    position: relative;

    &--full-height {
      height: 100%;
    }

    img {
      width: 100%;

      @include media-breakpoint-down(sm) {
        height: 100%;
        object-fit: cover;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS */
          min-width: 600px;
        }
      }
    }
  }

  &__background-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $gradient;
  }

  &__background-pink-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $pink-gradient;
  }

  &__content {
    position: relative;
    margin: 0 auto;
  }

  &__title {
    max-width: 670px;
    margin: 15px auto 10px;
    cursor: pointer;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 28px !important;
    text-transform: capitalize;

    @include media-breakpoint-up(md) {
      margin: 0 auto 20px;
      font-size: 58px !important;
      line-height: 66px !important;
    }
  }

  &__preamble {
    max-width: 670px;
    margin: 0 auto 20px;
    font-size: 12px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      margin: 0 auto 24px;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__menu {
    position: absolute;
    top: 10px !important;
    right: 10px;

    @include media-breakpoint-down(md) {
      top: 15px;
      right: 20px;
    }

    &-link {
      margin-right: 10px;
      color: $white;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__subscription {
    position: relative;
    font-size: small;
    padding: 0 20px;
    margin: 10px auto 0;
    color: $white;
    text-align: left;

    @include media-breakpoint-up(md) {
      max-width: 1150px;
      display: flex;
      flex-direction: row-reverse;
      padding: 0 40px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 120px;
    }

    &-text-wrapper {
      max-width: -moz-fit-content;
      max-width: fit-content;
      margin: 0 auto;

      > span {
        display: block;
      }
      
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    &-link {
      text-decoration: underline;
      color: $white;

      @include media-breakpoint-up(md) {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__filter-holder {
    max-width: 1150px;
    padding: 0 20px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      padding: 30px 0 0;
    }

    &--opened .insights-database-header-box__arrow-icon {
      transform: rotate(180deg);
    }
  }

  &__filter-label {
    display: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
    }
  }

  &__filter-label-mobile {
    display: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    vertical-align: top;

    @include media-breakpoint-down(sm) {
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
    }
  }

  &__filter-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;

    &-enter,
    &-enter-done {
      max-height: 232px;

      @include media-breakpoint-down(sm) {
        max-height: 500px;
      }
    }

    &-enter-done {
      overflow: visible;
    }

    &-exit,
    &-exit-done {
      overflow: hidden;
      max-height: 0;
    }
  }

  &__filter-container-inner {
    padding: 20px 0 28px;

    @include media-breakpoint-down(sm) {
      padding: 30px 0 5px;
    }
  }

  &__arrow-icon {
    display: inline-block;
    width: 18px;
    height: 22px;
    fill: $white;
    vertical-align: top;
  }
}
