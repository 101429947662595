@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';
@import 'styles/helpers.scss';

.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #f3f9fa;
  opacity: 0;
  word-wrap: break-word;

  p {
    color: #5e5e5e;
  }

  .icon--cancel {
    z-index: 3;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: $mine-shaft;
    padding: 1px 1px 1px 2px;
  }

  &.entered {
    opacity: 1;

    .modal__body {
      transform: translate(0, 0);
    }
  }

  &__body {
    margin: 1rem;
    border-radius: 4px;
  }

  &__header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .icon--logo {
      width: 33px;
      height: 28px;
    }

    .cls-1 {
      fill: none;
    }

    .cls-2 {
      fill: #b4e6f2;
    }

    .cls-3 {
      fill: $white;
    }
  }

  &__title {
    margin-top: 0;
  }

  &__input {
    width: 100%;
    padding: 5px;
    border: 0.5px solid $text-color;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  &__button-wrapper-center {
    text-align: center;

    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  &__error-message {
    margin: 0;
    margin-bottom: 10px;
    margin-top: 6px;
    color: $signal-red;
    min-height: 28px;
    line-height: 1.25;
  }

  &__footer {
    min-width: 100%;
    background: #f4f4f4;
    background-color: #f4f4f4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    touch-action: none;

    > div,
    > button {
      width: 242px;
    }
  }

  &--sm,
  &--md {
    .modal__body {
      margin: 1rem;
      background: $white;
      box-shadow: 0 3px 50px rgba(0, 0, 0, 0.16);
      transform: translate(0, -25%);
      transition: transform 0.15s ease-out;

      @include media-breakpoint-up(sm) {
        margin: 3rem auto;
      }
    }

    @include media-breakpoint-up(md) {
      box-shadow: 0 3px 50px rgba(0, 0, 0, 0.16);
    }
  }

  &--sm {
    .modal__body {
      max-width: 500px;
      padding: 20px;
    }
  }
  
  &--md {
    .modal__body {
      max-width: 730px;
    }

    .accordionPadding {
      padding: 20px 30px;
    }
  }

  &--full {
    .modal__body {
      background: transparent;
    }
  }

  &--show-menu {
    top: 46px;
    height: calc(100% - 46px);

    @include media-breakpoint-up(md) {
      top: 80px;
      height: calc(100% - 48px);
    }
  }

  &--transparency {
    overflow: auto;
    background: rgba(0, 36, 54, 0.8);

    &--mobile-search {
      background: rgba(0, 36, 54, 0.3);
    }

    @include if-ie() {
      background: rgba(0, 36, 54, 0.97);
    }
  }

  &--expanded-mobile {
    height: 100%;
    padding: 0;

    .modal__footer,
    .modal__header {
      border-radius: 0;
    }

    @include media-breakpoint-up(md) {
      .modal__footer {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .modal__header {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    .modal__body {
      height: 100%;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        height: auto;
        max-height: 722px;
        margin: 3rem auto;
        border-radius: 4px;
      }
    }
  }

  &--vertical-align {
    .modal__body {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% - 2rem);
      margin: 0;
      transform: translate(-50%, -75%);
    }

    &.entered {
      .modal__body {
        transform: translate(-50%, -50%);
      }
    }
  }

  &--no-border-radius {
    .modal__body {
      border-radius: 0;
    }
  }

  &--animation {
    &-fast {
      transition: opacity 0.05s linear;
    }

    &-slow {
      transition: opacity 0.25s linear;
    }
  }
}

.modal-open {
  overflow: hidden;

  &--overlay-blur {
    .pageheader > div,
    .pageheader > nav,
    .main-content > div,
    .navigation,
    .footer {
      filter: blur(50px);
    }
  }

  &--show-menu {
    .pageheader {
      > div {
        position: relative;
        z-index: 11;
        background: white;
        filter: none;
      }
    }
  }
}
