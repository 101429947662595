.accordion-wrapper {
  .accordion-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    user-select: initial;
    display: block;

    &-header-row__number {
      color: #125468;
      pointer-events: none;
      position: absolute;
      top: 13px;
      left: 16px;

      ~label {
        padding-left: 60px !important;
      }
    }

    input {
      position: absolute;
      opacity: 0;

      ~.accordion-container__body {
        display: none;
      }
    }

    input:checked {
      ~.accordion-container__open-icon {
        transform: rotate(0);
      }

      ~.accordion-container__body {
        max-height: fit-content;
        cursor: text;
        padding: 0 1em;
        margin: 0;
        display: block;
      }
    }

    label {
      display: inline-block;
      width: 100%;
      cursor: pointer;
      min-height: 51px;
      padding: 12px 21px 12px 16px;
      color: #125468;
    }

    &__open-icon {
      transform: rotate(180deg);
      position: absolute;
      right: 24px;
      top: 22px;
      pointer-events: none;
    }
  }
}
