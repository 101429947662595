@import 'styles/colors.scss';

.innovation-search-bar {
  &__suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - 40px);
    background: white;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    box-shadow: 0 2px 3px -1px rgba(63, 63, 63, 0.32);
  }
}