@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';


.sidebar-box {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  max-width: 280px;
  padding-left: 40px;
  word-break: normal;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &__item {
    margin: 0 0 20px;
  }

  &__item-label {
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    color: $dusty-gray;
    margin: 0 0 5px;
  }

  &__item-value {
    font-weight: 600;
    font-size: 14px;
    color: $mine-shaft;
  }

  &__item-link {
    text-decoration: none;
    color: $mine-shaft !important;

    &:after {
      content: ', ';
    }

    &:last-child:after {
      content: '';
    }
  }
}
